import { Button } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

interface PreviousButtonProps {
  route: string;
  disabled?: boolean;
}

export default function PreviousButton({
  route,
  disabled,
}: PreviousButtonProps) {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Button
      color="primary"
      variant="contained"
      fullWidth
      disabled={disabled}
      onClick={() => history.push(route)}
    >
      {t('common.previous')}
    </Button>
  );
}
