import axios from 'axios';
import { useEffect, useState } from 'react';

export default function useAnswerValues<V>(fields?: string[]) {
  const [values, setValues] = useState<V>({} as V);
  const [loading, setLoading] = useState(true);
  const [errorLoadingValues, setError] = useState(false);

  useEffect(() => {
    const fetchAnswerValues = async () => {
      setLoading(true);
      setError(false);

      try {
        const res = await axios.get(
          `${<string>process.env.REACT_APP_DATA_API_URL}/data`,
          {
            params: { fields },
            withCredentials: true,
          }
        );

        setValues(<V>res.data);
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    void fetchAnswerValues();
  }, []);

  return { values, loading, errorLoadingValues };
}
