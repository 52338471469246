import { Box, Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import AppBar from '../AppBar';
import { FormContent, OhjeetSection } from '../elements';
import useStyles from '../styles';

export default function Ohjeet() {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <AppBar />

      <FormContent>
        <OhjeetSection title={t('ohjeet.title1')}>
          <Box mb={1}>
            <Typography variant="body1">{t('ohjeet.instructions1')}</Typography>
          </Box>
          <Box>
            <Typography variant="body1">{t('ohjeet.instructions2')}</Typography>
          </Box>
        </OhjeetSection>

        <OhjeetSection title={t('ohjeet.title2')}>
          <Typography variant="body1">{t('ohjeet.instructions3')}</Typography>

          <Typography variant="body1">
            <strong>{t('ohjeet.instructions4_bold')}</strong>&nbsp;
            {t('ohjeet.instructions4')}
          </Typography>

          <Typography variant="body1">
            <strong>{t('ohjeet.instructions5_bold')}</strong>&nbsp;
            {t('ohjeet.instructions5')}
          </Typography>

          <Typography variant="body1">{t('ohjeet.instructions6')}</Typography>
        </OhjeetSection>

        <OhjeetSection title={t('ohjeet.kalastusmatkat')}>
          <Typography variant="body1">
            <strong>{t('common.example')} 1</strong>:&nbsp;
            {t('kalastusmatkat.example1')}
          </Typography>

          <Typography variant="body1">
            <strong> {t('common.example')} 2</strong>:&nbsp;
            {t('kalastusmatkat.example2')}
          </Typography>

          <Typography variant="body1">
            <strong>{t('common.example')} 3</strong>:&nbsp;
            {t('kalastusmatkat.example3')}
          </Typography>
        </OhjeetSection>

        <OhjeetSection title={t('ohjeet.title3')}>
          <Typography variant="body1">
            <a href="mailto:kala-riistatilastot@luke.fi">
              kala-riistatilastot@luke.fi
            </a>
          </Typography>
        </OhjeetSection>

        <Box mt={3} ml={3}>
          <div className={classes.formFooterDesktop}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => history.goBack()}
            >
              {t('common.close')}
            </Button>
          </div>
        </Box>
      </FormContent>

      <div className={classes.formFooter}>
        <Grid container item xs={12} justifyContent="flex-end">
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => history.goBack()}
          >
            {t('common.close')}
          </Button>
        </Grid>
      </div>
    </>
  );
}
