import { AuthActions, DELETE_TOKEN, SET_TOKEN } from './types';

export function setToken(token: string): AuthActions {
  return {
    type: SET_TOKEN,
    payload: token,
  };
}

export function deleteToken(): AuthActions {
  return {
    type: DELETE_TOKEN,
  };
}
