import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useOhjeetStyles = makeStyles(() => ({
  ohjeetSection: {
    '& > p': {
      'margin-bottom': '.5rem',
    },
  },
}));

export default function OhjeetSection({
  title,
  children,
}: React.PropsWithChildren<{ title: string }>) {
  const classes = useOhjeetStyles();

  return (
    <>
      <Box mt={3} ml={3} mb={2}>
        <Typography variant="h5" component="h2">
          {title}
        </Typography>
      </Box>

      <Box mt={1} ml={3} mb={3} className={classes.ohjeetSection}>
        {children}
      </Box>
    </>
  );
}
