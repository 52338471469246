export type AgeGroup = {
  id: number;
  name: {
    [key: string]: string;
  };
};

export const ageGroups: AgeGroup[] = [
  {
    id: 1,
    name: {
      fi: 'alle 10v',
      sv: 'under 10 år',
      en: 'under 10 y/o',
    },
  },
  {
    id: 2,
    name: {
      fi: '10-17v',
      sv: '10-17 år',
      en: '10-17 y/o',
    },
  },
  {
    id: 3,
    name: {
      fi: '18-24v',
      sv: '18-24 år',
      en: '18-24 y/o',
    },
  },
  {
    id: 4,
    name: {
      fi: '25-44v',
      sv: '25-44 år',
      en: '18-24 y/o',
    },
  },
  {
    id: 5,
    name: {
      fi: '45-64v',
      sv: '45-64 år',
      en: '45-64 y/o',
    },
  },
  {
    id: 6,
    name: {
      fi: '65v+',
      sv: '65 år eller över',
      en: '65 y/o or over',
    },
  },
  {
    id: 999,
    name: {
      fi: 'Tuntematon',
      sv: 'Obekant',
      en: 'Unknown',
    },
  },
];

export default ageGroups;
