import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  screenOnly: {
    '@media print': {
      display: 'none !important',
    },
  },
  printOnly: {
    '@media screen': {
      display: 'none !important',
    },
  },
  mobileOnly: {
    '@media (min-width: 968px)': {
      display: 'none !important',
    },
  },
  srOnly: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: '1px',
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: '1px',
  },
  formPage: {
    backgroundColor: '#fff',
    '@media (min-width: 968px)': {
      margin: '50px 100px',
      padding: '50px',
      height: 'auto !important', // using calc overrides media queries on some browsers
    },
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    boxSizing: 'border-box',
  },
  formContent: {
    position: 'relative',
    backgroundColor: '#fff',
    '@media (min-width: 968px)': {
      margin: '50px 100px',
      padding: '50px',
      flexGrow: 0,
      overflow: 'hidden',
    },
    overflow: 'scroll',
    boxSizing: 'border-box',
    height: '100%',
  },
  formFooter: {
    '@media (min-width: 968px), print': {
      display: 'none',
    },
    height: '36px',
    position: 'sticky',
    right: 0,
    left: 0,
    width: '100%',
    bottom: 0,
  },
  formFooterDesktop: {
    '@media (min-width: 968px)': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    display: 'none',
  },
  gridHeader: {
    overflowWrap: 'break-word',
  },
  gridLabel: {
    marginTop: '5px',
    '& p': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'pre-wrap',
    },
  },
  instructions: {
    backgroundColor: '#e4f6cd',
    whiteSpace: 'pre-line',
  },
  desktopFlags: {
    '@media (min-width: 968px)': {
      display: 'flex',
    },
    display: 'none',
    justifyContent: 'flex-end',
    '& img': {
      marginLeft: '5px',
      cursor: 'pointer',
    },
  },
  desktopLoginButton: {
    '@media (min-width: 968px)': {
      display: 'flex',
      alignItems: 'flex-end',
      paddingLeft: '25px',
    },
    display: 'none',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  thankYouPage: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  areasSvg: {
    userSelect: 'none',
    msUserSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    maxHeight: '800px',
    '& path': {
      cursor: 'pointer',
      pointerEvents: 'all',
      fill: 'transparent',
      strokeWidth: '1.07',
      stroke: '#595959',
      transition: '0.08s linear',
    },
    '& path.selected': {
      fill: '#78be20',
    },
    '& line, & polyline, & polygon, & rect, & circle': {
      fill: 'none',
      stroke: '#000000',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeMiterlimit: '10',
      outline: 'none',
    },
  },
  areasSvgLabel: {
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    pointerEvents: 'none',
    fontSize: '12px',
    fontWeight: 'bold',
    fontFamily: 'Titillium Web',
    '&.sea': {
      fill: '#00b4e1;',
    },
  },
}));

export default useStyles;
