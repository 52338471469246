import * as yup from 'yup';

const numberField = yup
  .number()
  .typeError('common.errors.invalid_value')
  .min(0, 'common.errors.invalid_value')
  .transform(value => (isNaN(value) ? undefined : value))
  .nullable();

const requiredNumberField = numberField.required();

const optionalNumberField = numberField
  .integer('common.errors.invalid_value')
  .nullable();

const optionalDecimalField = numberField.nullable();

export {
  numberField,
  requiredNumberField,
  optionalNumberField,
  optionalDecimalField,
};
