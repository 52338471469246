import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AppBar from './AppBar';
import { useTokenState } from './hooks';
import useTokenCookieParser from './hooks/useTokenCookieParser';
import {
  Alueet,
  Asuntokunta,
  AsuntokuntaHarrastaneet,
  AsuntokuntaKalastaneet,
  AsuntokuntaKalastuspaivat,
  AsuntokuntaOsallistuneet,
  AsuntokuntaPyydykset,
  Kalastusmatkat,
  Lisatiedot,
  PyydyksetAlueittain,
  SaaliitMaarat,
  SaaliitPyydyksittain,
  SaaliitRavinnoksi,
  SaaliitVapautetut,
  Vastauskoodi,
  Yhteenveto,
} from './pages';
import { FormPath, formPaths } from './pages/formPaths';

const pathComponentMap: Readonly<
  Record<FormPath, React.ComponentType<unknown>>
> = {
  [FormPath.VASTAUSKOODI]: Vastauskoodi,
  [FormPath.ASUNTOKUNTA]: Asuntokunta,
  [FormPath.ASUNTOKUNTA_KALASTANEET]: AsuntokuntaKalastaneet,
  [FormPath.ASUNTOKUNTA_OSALLISTUNEET]: AsuntokuntaOsallistuneet,
  [FormPath.ASUNTOKUNTA_HARRASTANEET]: AsuntokuntaHarrastaneet,
  [FormPath.ASUNTOKUNTA_KALASTUSPAIVAT]: AsuntokuntaKalastuspaivat,
  [FormPath.ALUEET]: Alueet,
  [FormPath.ASUNTOKUNTA_PYYDYKSET]: AsuntokuntaPyydykset,
  [FormPath.PYYDYKSET_ALUEITTAIN]: PyydyksetAlueittain,
  [FormPath.KALASTUSMATKAT]: Kalastusmatkat,
  [FormPath.LAJIT_SAALIIT]: SaaliitPyydyksittain,
  [FormPath.SAALIIT_MAARAT]: SaaliitMaarat,
  [FormPath.SAALIIT_RAVINNOKSI]: SaaliitRavinnoksi,
  [FormPath.SAALIIT_VAPAUTETUT]: SaaliitVapautetut,
  [FormPath.LISATIEDOT]: Lisatiedot,
  [FormPath.YHTEENVETO]: Yhteenveto,
};

export default function AppShell() {
  // parse tikal token if present
  useTokenCookieParser();

  const isAuthenticated = useTokenState();

  //If there's no token, user is returned to login page
  if (!isAuthenticated) {
    return <Redirect to="/kirjaudu" />;
  }

  return (
    <React.Fragment>
      <AppBar />
      <Switch>
        {formPaths.map((path) => (
          <Route
            key={path}
            path={`/lomake/${path}`}
            component={pathComponentMap[path]}
          />
        ))}
      </Switch>
    </React.Fragment>
  );
}
