import axios from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setToken } from '../store/auth/actions';

export default function useAuth() {
  const [authenticating, setAuthenticating] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const authenticate = async (Authkey: string) => {
    //Jos Authkey löytyy, eli parametri on annettu, jatka normaalisti
    //jos tästä tulee true, eli Authkeytä ei ole annettu(!false = true) skipataan authenticate
    //  turha postailla tyhjää Authkeytä, nappi toimii vasta kun käyttäjä antaa sisältöä
    if (!Authkey) return;

    setError(false);
    setAuthenticating(true);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_AUTHENTICATION_API_URL}/authToken?app_id=ahti`,
        {
          Authkey,
        }
      );

      dispatch(setToken(res.data.token));
    } catch (e) {
      setError(true);
      setAuthenticating(false);
    }
  };

  return {
    authenticating,
    error,
    authenticate,
  };
}
