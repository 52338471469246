import { Box } from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { EnterAwareTextField, FormLayout } from '../elements';
import { useFormPage, useFormPath } from '../hooks';
import Fishing from '../types/Fishing';
import { FormValues } from '../types/FormValues';
import { FormPath, getPrevPath } from './formPaths';

type LisatiedotValues = Pick<FormValues, 'lisatiedot' | 'asuntokunta_kalastus'>;

const schema = yup.object().shape({
  lisatiedot: yup.string(),
});

function useResolvedPrevRoute(values: LisatiedotValues) {
  const currentFormPath = useFormPath();

  if (values.asuntokunta_kalastus == Fishing.NO_FISHING) {
    return FormPath.ASUNTOKUNTA;
  }

  if (values.asuntokunta_kalastus == Fishing.FISHING_NO_CATCH) {
    return FormPath.KALASTUSMATKAT;
  }

  return getPrevPath(currentFormPath)!;
}

export default function Lisatiedot() {
  const { t } = useTranslation();
  const { control, handleSubmit, initialValues, syncing } =
    useFormPage<LisatiedotValues>({
      fields: ['lisatiedot', 'asuntokunta_kalastus'],
      schema,
    });
  const prevRoute = useResolvedPrevRoute(initialValues);

  return (
    <FormLayout
      heading={t('lisatiedot.title')}
      syncing={syncing}
      onSubmit={handleSubmit}
      prevRoute={prevRoute}
    >
      <Box p={3}>
        <Controller
          control={control}
          defaultValue={''}
          name="lisatiedot"
          render={({ field, fieldState: { error } }) => (
            <EnterAwareTextField
              {...field}
              disabled={syncing}
              error={!!error}
              fullWidth
              inputProps={{
                'aria-label': t('lisatiedot.title')!,
              }}
              multiline
            />
          )}
        />
      </Box>
    </FormLayout>
  );
}
