import { Box, Divider, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Area, EnterAwareTextField, FormLayout } from '../elements';
import { useFormPage } from '../hooks';
import traps, { getTrapName } from '../options/traps';
import useStyles from '../styles';
import { FormValues } from '../types/FormValues';
import { findIdsOfUsedTraps, schema } from './PyydyksetAlueittain.model';

export type PyydyksetAlueittainValues = Pick<
  FormValues,
  | 'pyydykset_alueittain'
  | 'alueet'
  | 'muu_pyydys'
  | 'asuntokunta_pyydykset_ikaryhmittain'
>;

export default function PyydyksetAlueittain() {
  const { t, i18n } = useTranslation();
  const classes = useStyles({});
  const { control, handleSubmit, register, initialValues, syncing } =
    useFormPage<PyydyksetAlueittainValues>({
      fields: [
        'pyydykset_alueittain',
        'alueet',
        'muu_pyydys',
        'asuntokunta_pyydykset_ikaryhmittain',
      ],
      schema,
    });

  const usedTraps = useMemo(
    () => findIdsOfUsedTraps(initialValues.asuntokunta_pyydykset_ikaryhmittain),
    [initialValues.asuntokunta_pyydykset_ikaryhmittain]
  );

  return (
    <FormLayout
      heading={t('pyydykset_alueittain.title')}
      formInstructions={[
        'pyydykset_alueittain.instructions1',
        'pyydykset_alueittain.instructions2',
      ]}
      syncing={syncing}
      onSubmit={handleSubmit}
    >
      <Box p={3}>
        {(initialValues.alueet || []).map((value, index) => (
          <React.Fragment key={value}>
            <Box mb={4} mt={2}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6" component="h3">
                    <Area id={value} hideNumber />
                  </Typography>

                  <input
                    type="hidden"
                    defaultValue={value}
                    {...register(`pyydykset_alueittain.${index}.alue`)}
                  />
                </Grid>

                <Grid item xs={4}></Grid>

                <Grid item xs={4}>
                  <Typography>
                    {t('pyydykset_alueittain.control.label1')}
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography>
                    {t('pyydykset_alueittain.control.label2')}
                  </Typography>
                </Grid>

                {traps.map((trap, nthTrap) => (
                  <React.Fragment key={trap.id}>
                    {usedTraps.includes(trap.id) ? (
                      <>
                        <Grid item xs={4} className={classes.gridLabel}>
                          <Typography data-testid="trap-label">
                            {getTrapName(trap.id, i18n.language, initialValues)}
                          </Typography>

                          <input
                            type="hidden"
                            defaultValue={trap.id}
                            {...register(
                              `pyydykset_alueittain.${index}.pyydykset.${nthTrap}.id`
                            )}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Controller
                            control={control}
                            name={`pyydykset_alueittain.${index}.pyydykset.${nthTrap}.henkilot`}
                            render={({ field, fieldState: { error } }) => (
                              <EnterAwareTextField
                                {...field}
                                type="number"
                                error={!!error}
                                helperText={!!error && t(error.message!)}
                                disabled={syncing}
                                inputProps={{
                                  'aria-label': `Pyydystä ${trap.name.fi} käyttäneet henkilöt alueella`,
                                }}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Controller
                            control={control}
                            name={`pyydykset_alueittain.${index}.pyydykset.${nthTrap}.kalastuspaivat`}
                            render={({ field, fieldState: { error } }) => (
                              <EnterAwareTextField
                                {...field}
                                type="number"
                                disabled={syncing}
                                helperText={!!error && t(error.message!)}
                                error={!!error}
                                inputProps={{
                                  'aria-label': `Pyydystä ${trap.name.fi} käyttäneiden henkilöiden yhteenlasketut kalastuspäivät alueella`,
                                }}
                              />
                            )}
                          />
                        </Grid>
                      </>
                    ) : (
                      <input
                        type="hidden"
                        defaultValue={trap.id}
                        {...register(
                          `pyydykset_alueittain.${index}.pyydykset.${nthTrap}.id`
                        )}
                      />
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </Box>

            {index !== (initialValues.alueet || []).length - 1 && (
              <Divider light />
            )}
          </React.Fragment>
        ))}
      </Box>
    </FormLayout>
  );
}
