/**
 * Form step paths (form sub pages)
 *
 */
export enum FormPath {
  VASTAUSKOODI = '0',
  ASUNTOKUNTA = '1',
  ASUNTOKUNTA_KALASTANEET = '2',
  ASUNTOKUNTA_OSALLISTUNEET = '3',
  ASUNTOKUNTA_HARRASTANEET = '4',
  ASUNTOKUNTA_KALASTUSPAIVAT = '5',
  ALUEET = '6',
  ASUNTOKUNTA_PYYDYKSET = '7',
  PYYDYKSET_ALUEITTAIN = '8',
  KALASTUSMATKAT = '9',
  LAJIT_SAALIIT = '10a',
  SAALIIT_MAARAT = '10b',
  SAALIIT_RAVINNOKSI = '10c',
  SAALIIT_VAPAUTETUT = '11',
  LISATIEDOT = '12',
  YHTEENVETO = 'yhteenveto',
}

export const formPaths = Object.values(FormPath);

/**
 * Get form's next step's path
 * @param path
 * @returns
 */
export const getNextPath = (path: FormPath): FormPath | undefined =>
  formPaths[formPaths.indexOf(path) + 1];

/**
 * Get form's next step's path
 * @param path
 * @returns
 */
export const getPrevPath = (path: FormPath): FormPath | undefined =>
  formPaths[formPaths.indexOf(path) - 1];

/**
 * Return full path for form sub page
 * @param path
 * @returns
 */
export const getFullFormPath = (path: FormPath): string => `/lomake/${path}`;
