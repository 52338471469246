import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { FormLayout } from '../elements';
import { useFormPage, useFormPath, useUserRole } from '../hooks';
import Fishing from '../types/Fishing';
import { FormValues } from '../types/FormValues';
import { FormPath, getNextPath } from './formPaths';

const schema = yup.object().shape({
  asuntokunta_kalastus: yup.string().oneOf(Object.values(Fishing)).required(),
});

type AsuntokuntaValues = Pick<FormValues, 'asuntokunta_kalastus'>;

export default function Asuntokunta() {
  const currentFormPath = useFormPath();
  const { isAdmin } = useUserRole();
  const { t } = useTranslation();
  const { control, handleSubmit, syncing } = useFormPage<AsuntokuntaValues>({
    schema,
    routes: {
      next: (data) =>
        data.asuntokunta_kalastus === Fishing.NO_FISHING
          ? FormPath.LISATIEDOT
          : getNextPath(currentFormPath)!,
    },
    fields: ['asuntokunta_kalastus'],
  });

  return (
    <FormLayout
      heading={t('asuntokunta.title')}
      formInstructions={[
        'asuntokunta.instructions1',
        'asuntokunta.instructions2',
      ]}
      prevRoute={isAdmin ? FormPath.VASTAUSKOODI : false}
      syncing={syncing}
      onSubmit={handleSubmit}
    >
      <Box p={3}>
        <Controller
          name="asuntokunta_kalastus"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl error={!!error}>
              <RadioGroup
                {...field}
                value={field.value || ''}
                aria-label={t('asuntokunta.control.label')!}
              >
                <FormControlLabel
                  disabled={syncing}
                  value={Fishing.FISHING}
                  control={<Radio />}
                  label={t('asuntokunta.control.option1')}
                />
                <FormControlLabel
                  disabled={syncing}
                  value={Fishing.FISHING_NO_CATCH}
                  control={<Radio />}
                  label={t('asuntokunta.control.option2')}
                />
                <FormControlLabel
                  disabled={syncing}
                  value={Fishing.NO_FISHING}
                  control={<Radio />}
                  label={t('asuntokunta.control.option3')}
                />
              </RadioGroup>

              {!!error && (
                <FormHelperText error data-testid={`${field.name}-error`}>
                  {t('asuntokunta.control.error')}
                </FormHelperText>
              )}
            </FormControl>
          )}
        />
      </Box>
    </FormLayout>
  );
}
