import { Box, Typography } from '@material-ui/core';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import LukeLogoEN from '../assets/Luke_EN.png';
import LukeLogoFI from '../assets/Luke_FI.png';
import LukeLogoSE from '../assets/Luke_SE.png';
import statisticsLogo from '../assets/svt_logo_etusivu_saatekirje_print.jpg';
import useStyles from '../styles';
import LocalDateTime from './LocalDateTime';

interface LukeLogoProps {
  lang: string;
}

function resolveLogoPath(lang: string) {
  if (lang === 'en') return LukeLogoEN as string;
  if (lang === 'sv') return LukeLogoSE as string;
  return LukeLogoFI as string;
}

function LukeLogo({ lang }: LukeLogoProps) {
  const { t } = useTranslation();
  const logoSrc = resolveLogoPath(lang);
  return <img src={logoSrc} alt={t('common.lukeLogoAlt')} height={90} />;
}

function PrintHeader() {
  const { printOnly } = useStyles();
  const { t, i18n } = useTranslation();
  const today = useRef(new Date());

  return (
    <Box px={2} className={printOnly}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <LukeLogo lang={i18n.language} />
        <img
          src={statisticsLogo as string}
          alt={t('common.officialStatisticsLogoAlt')}
        />
      </Box>

      <Typography variant="body1" component="section">
        {t('common.title', { year: today.current.getFullYear() })}
        <br />
        <LocalDateTime date={today.current} />
      </Typography>
    </Box>
  );
}

export default PrintHeader;
