import { get as getCookie, remove as removeCookie } from 'js-cookie';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setToken } from '../store/auth/actions';

export default function useTokenCookieParser() {
  const dispatch = useDispatch();
  const token = getCookie('TikalToken');

  useEffect(() => {
    if (token) {
      dispatch(setToken(token));
      removeCookie('TikalToken', {
        domain:
          process.env.NODE_ENV === 'development' ? 'localhost' : '.luke.fi',
      });
    }
  }, [token]);
}
