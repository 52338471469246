import React from 'react';
import Flag from 'react-flagkit';
import { useTranslation } from 'react-i18next';

export default function FlagButton({
  country,
  lng,
}: {
  country: string;
  lng: string;
}) {
  const { t, i18n } = useTranslation();

  return (
    <Flag
      role="button"
      title={t(`lang.${lng}`)}
      country={country.toUpperCase()}
      tabIndex={0}
      onClick={() => i18n.changeLanguage(lng)}
      onKeyDown={e => (e.key == 'Enter' ? i18n.changeLanguage(lng) : null)}
    ></Flag>
  );
}
