import { Box, Divider, Grid, InputAdornment } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import {
  Area,
  EnterAwareTextField,
  FormLayout,
  Species,
  SpeciesUnit,
} from '../elements';
import { useFormPage } from '../hooks';
import { getTrapName } from '../options/traps';
import useStyles from '../styles';
import { FormValues } from '../types/FormValues';
import { optionalDecimalField } from '../util/numberField';

const schema = yup.object().shape({
  saalismaarat: yup.array().of(
    yup.object().shape({
      alue: yup.string().required(),
      pyydykset: yup.array().of(
        yup.object().shape({
          id: yup.number().required(),
          lajit: yup.array().of(
            yup.object().shape({
              id: yup.number(),
              maara: optionalDecimalField,
            })
          ),
        })
      ),
    })
  ),
});

type SaaliitMaaratValues = Pick<
  FormValues,
  'saaliit' | 'saalismaarat' | 'muu_pyydys'
>;

export default function SaaliitMaarat() {
  const { t, i18n } = useTranslation();
  const classes = useStyles({});
  const { control, handleSubmit, register, initialValues, syncing } =
    useFormPage<SaaliitMaaratValues>({
      fields: ['saaliit', 'saalismaarat', 'muu_pyydys'],
      schema,
    });

  return (
    <FormLayout
      heading={t('saaliit_maarat.title')}
      syncing={syncing}
      onSubmit={handleSubmit}
    >
      <Box p={3}>
        {(initialValues.saaliit || []).map((value: any, index: any) => (
          <React.Fragment key={value.alue}>
            <Box mb={4} mt={2}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h3">
                    <Area id={value.alue} hideNumber />
                  </Typography>

                  <input
                    type="hidden"
                    defaultValue={value.alue}
                    {...register(`saalismaarat.${index}.alue`)}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="h4">{t('common.catch')}</Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="h4">{t('common.species')}</Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="h4">{t('common.amount')}</Typography>
                </Grid>

                {(value.pyydykset || []).map((row: any, j: number) => (
                  <React.Fragment key={`${value.alue}-${row.id}`}>
                    <input
                      type="hidden"
                      defaultValue={row.id}
                      {...register(`saalismaarat.${index}.pyydykset.${j}.id`)}
                    />

                    {row.lajit.map((species: any, k: number) => (
                      <React.Fragment key={`${row.id}-${species}`}>
                        <Grid item xs={4} className={classes.gridLabel}>
                          <Typography>
                            {k === 0
                              ? getTrapName(
                                  row.id,
                                  i18n.language,
                                  initialValues
                                )
                              : ''}
                          </Typography>

                          <input
                            {...register(
                              `saalismaarat.${index}.pyydykset.${j}.lajit.${k}.id`
                            )}
                            type="hidden"
                            defaultValue={species}
                          />
                        </Grid>

                        <Grid item xs={4} className={classes.gridLabel}>
                          <Typography>
                            <Species id={species} />
                          </Typography>
                        </Grid>

                        <Grid item xs={4}>
                          <Controller
                            control={control}
                            name={`saalismaarat.${index}.pyydykset.${j}.lajit.${k}.maara`}
                            render={({ field, fieldState: { error } }) => (
                              <EnterAwareTextField
                                {...field}
                                type="number"
                                disabled={syncing}
                                error={!!error}
                                helperText={
                                  !!error && t('common.errors.invalid_value')
                                }
                                inputProps={{
                                  step: 0.1,
                                  'aria-label': `Lajin ${species} saalis alueella ${
                                    value.alue
                                  } pyydyksellä ${getTrapName(
                                    row.id,
                                    i18n.language,
                                    initialValues
                                  )}`,
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <SpeciesUnit id={species} />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </Grid>
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </Grid>
            </Box>

            {index !== (initialValues.saaliit || []).length - 1 && (
              <Divider light />
            )}
          </React.Fragment>
        ))}
      </Box>
    </FormLayout>
  );
}
