import { Box, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { EnterAwareTextField, FormLayout } from '../elements';
import { useFormPage, useUserRole } from '../hooks';
import ageGroups from '../options/age_groups';
import useStyles from '../styles';
import { FormValues } from '../types/FormValues';
import { isAuthorizedForMetaData } from '../util/auth';
import { optionalNumberField } from '../util/numberField';

const schema = yup.object().shape({
  asuntokunta_ikaryhmat: yup.array().of(
    yup.object().shape({
      id: yup.number().required(),
      henkilot: yup.array().of(
        yup.object().shape({
          sukupuoli: yup.string().oneOf(['naiset', 'miehet']),
          lkm: optionalNumberField.max(
            20,
            'asuntokunta_kalastaneet.control.error1'
          ),
        })
      ),
    })
  ),
});

type AsuntokuntaKalastaneetValues = Pick<FormValues, 'asuntokunta_ikaryhmat'>;

export default function AsuntokuntaKalastaneet() {
  const { t, i18n } = useTranslation();
  const classes = useStyles({});
  const { role } = useUserRole();
  const { control, handleSubmit, register, syncing } =
    useFormPage<AsuntokuntaKalastaneetValues>({
      schema,
      fields: ['asuntokunta_ikaryhmat'],
    });

  return (
    <FormLayout
      heading={t('asuntokunta_kalastaneet.title')}
      formInstructions={['asuntokunta_kalastaneet.instructions1']}
      syncing={syncing}
      onSubmit={(e) => void handleSubmit(e)}
    >
      <Box p={3}>
        <Grid container spacing={1}>
          <Grid item xs={4}></Grid>

          <Grid item xs={4}>
            <Typography>{t('common.women')}</Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography>{t('common.men')}</Typography>
          </Grid>

          {ageGroups
            .filter(isAuthorizedForMetaData(role))
            .map((group, index) => (
              <Grid key={group.id} container spacing={1} item xs={12}>
                <Grid item xs={4} className={classes.gridLabel}>
                  <Typography>{group.name[i18n.language || 'fi']}</Typography>
                  <input
                    type="hidden"
                    defaultValue={group.id}
                    {...register(`asuntokunta_ikaryhmat.${index}.id`)}
                  />
                </Grid>

                {['naiset', 'miehet'].map((gender, j) => (
                  <Grid key={gender} item xs={4}>
                    <input
                      type="hidden"
                      defaultValue={gender}
                      {...register(
                        `asuntokunta_ikaryhmat.${index}.henkilot.${j}.sukupuoli`
                      )}
                    />
                    <Controller
                      control={control}
                      name={`asuntokunta_ikaryhmat.${index}.henkilot.${j}.lkm`}
                      render={({ field, fieldState: { error } }) => (
                        <EnterAwareTextField
                          {...field}
                          type="number"
                          disabled={syncing}
                          error={!!error}
                          helperText={!!error && t(error.message!)}
                          inputProps={{
                            'aria-label': `${
                              gender === 'naiset'
                                ? t('common.women')
                                : t('common.men')
                            }, ${group.name[i18n.language || 'fi']}`,
                          }}
                        />
                      )}
                    />
                  </Grid>
                ))}
              </Grid>
            ))}
        </Grid>
      </Box>
    </FormLayout>
  );
}
