import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { Trans } from 'react-i18next';

interface FormInstructionsProps {
  instructions: string[];
}

export default function FormInstructions({
  instructions,
}: FormInstructionsProps) {
  return (
    <Box p={3} pb={1} bgcolor="#e4f6cd">
      {instructions.map((key, i) => (
        <Box key={i} pb={2}>
          <Typography key={i} variant="body1">
            <Trans i18nKey={key}></Trans>
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
