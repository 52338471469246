import { Box, Divider, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Area, EnterAwareTextField, FormLayout } from '../elements';
import { useFormPage, useFormPath } from '../hooks';
import useStyles from '../styles';
import Fishing from '../types/Fishing';
import { FormValues } from '../types/FormValues';
import { optionalNumberField } from '../util/numberField';
import { FormPath, getNextPath } from './formPaths';

const schema = yup.object().shape({
  kalastusmatkat: yup.array().of(
    yup.object().shape({
      alue: yup.number().required(),
      paiva_kayntien_maara: optionalNumberField,
      kalastusmatkojen_maara: optionalNumberField,
      kalastuspaivien_maara_kalastusmatkoilla: optionalNumberField,
      polttoainekulut: optionalNumberField,
      matkalippukulut: optionalNumberField,
      majoituskulut: optionalNumberField,
      kahvilakulut_ja_ravintolakulut: optionalNumberField,
      ruokakulut_ja_paivittaistavarakulut: optionalNumberField,
      kalastuslupakulut: optionalNumberField,
      kalastuspalvelukulut: optionalNumberField,
      muut_ostot_menot: optionalNumberField,
    })
  ),
});

type KalastusmatkatValues = Pick<
  FormValues,
  'kalastusmatkat' | 'alueet' | 'asuntokunta_kalastus'
>;

export default function Kalastusmatkat() {
  const { t } = useTranslation();
  const classes = useStyles();
  const currentFormPath = useFormPath();
  const { control, handleSubmit, register, initialValues, syncing } =
    useFormPage<KalastusmatkatValues>({
      fields: ['kalastusmatkat', 'alueet', 'asuntokunta_kalastus'],
      schema,
      routes: {
        next: (data) =>
          data.asuntokunta_kalastus == Fishing.FISHING_NO_CATCH
            ? FormPath.LISATIEDOT
            : getNextPath(currentFormPath)!,
      },
    });

  return (
    <FormLayout
      heading={t('kalastusmatkat.title')}
      formInstructions={[
        'kalastusmatkat.instructions1',
        'kalastusmatkat.instructions2',
        'kalastusmatkat.instructions3',
        'kalastusmatkat.instructions5',
        'kalastusmatkat.instructions6',
      ]}
      syncing={syncing}
      onSubmit={(e) => void handleSubmit(e)}
    >
      <Box p={3}>
        {(initialValues.alueet || []).map((value, index) => (
          <React.Fragment key={value}>
            <Box mb={4} mt={2}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h3">
                    <Area id={value} hideNumber />
                  </Typography>

                  <input
                    type="hidden"
                    defaultValue={value}
                    {...register(`kalastusmatkat.${index}.alue`)}
                  />
                </Grid>

                <Grid item xs={6} className={classes.gridLabel}>
                  <Typography
                    htmlFor={`kalastusmatkat.${index}-paiva_kayntien_maara`}
                    component="label"
                  >{`a) ${t('kalastusmatkat.control.label1')}`}</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name={`kalastusmatkat.${index}.paiva_kayntien_maara`}
                    render={({ field, fieldState: { error } }) => (
                      <EnterAwareTextField
                        {...field}
                        id={`kalastusmatkat.${index}-paiva_kayntien_maara`}
                        type="number"
                        disabled={syncing}
                        helperText={!!error && t('common.errors.invalid_value')}
                        error={!!error}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6} className={classes.gridLabel}>
                  <Typography
                    htmlFor={`kalastusmatkat.${index}-kalastusmatkojen_maara`}
                    component="label"
                  >{`b) ${t('kalastusmatkat.control.label2')}`}</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name={`kalastusmatkat.${index}.kalastusmatkojen_maara`}
                    render={({ field, fieldState: { error } }) => (
                      <EnterAwareTextField
                        {...field}
                        id={`kalastusmatkat.${index}-kalastusmatkojen_maara`}
                        type="number"
                        disabled={syncing}
                        error={!!error}
                        helperText={!!error && t('common.errors.invalid_value')}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6} className={classes.gridLabel}>
                  <Typography
                    htmlFor={`kalastusmatkat.${index}-kalastuspaivien_maara_kalastusmatkoilla`}
                    component="label"
                  >{`c) ${t('kalastusmatkat.control.label3')}`}</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name={`kalastusmatkat.${index}.kalastuspaivien_maara_kalastusmatkoilla`}
                    render={({ field, fieldState: { error } }) => (
                      <EnterAwareTextField
                        {...field}
                        id={`kalastusmatkat.${index}-kalastuspaivien_maara_kalastusmatkoilla`}
                        type="number"
                        disabled={syncing}
                        error={!!error}
                        helperText={!!error && t('common.errors.invalid_value')}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6} className={classes.gridLabel}>
                  <Typography
                    htmlFor={`kalastusmatkat-label-${index}-polttoainekulut`}
                    component="label"
                  >
                    {`d) ${t('kalastusmatkat.control.label4')}`}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name={`kalastusmatkat.${index}.polttoainekulut`}
                    render={({ field, fieldState: { error } }) => (
                      <EnterAwareTextField
                        {...field}
                        id={`kalastusmatkat-label-${index}-polttoainekulut`}
                        type="number"
                        disabled={syncing}
                        error={!!error}
                        helperText={!!error && t('common.errors.invalid_value')}
                        InputProps={{ endAdornment: <>&nbsp;€</> }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6} className={classes.gridLabel}>
                  <Typography
                    htmlFor={`kalastusmatkat-label-${index}-matkalippukulut`}
                    component="label"
                  >
                    {`e) ${t('kalastusmatkat.control.label5')}`}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name={`kalastusmatkat.${index}.matkalippukulut`}
                    render={({ field, fieldState: { error } }) => (
                      <EnterAwareTextField
                        {...field}
                        id={`kalastusmatkat-label-${index}-matkalippukulut`}
                        type="number"
                        disabled={syncing}
                        error={!!error}
                        helperText={!!error && t('common.errors.invalid_value')}
                        InputProps={{ endAdornment: <>&nbsp;€</> }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6} className={classes.gridLabel}>
                  <Typography
                    htmlFor={`kalastusmatkat-label-${index}-majoituskulut`}
                    component="label"
                  >
                    {`f) ${t('kalastusmatkat.control.label6')}`}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name={`kalastusmatkat.${index}.majoituskulut`}
                    render={({ field, fieldState: { error } }) => (
                      <EnterAwareTextField
                        {...field}
                        id={`kalastusmatkat-label-${index}-majoituskulut`}
                        type="number"
                        disabled={syncing}
                        error={!!error}
                        helperText={!!error && t('common.errors.invalid_value')}
                        InputProps={{ endAdornment: <>&nbsp;€</> }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6} className={classes.gridLabel}>
                  <Typography
                    htmlFor={`kalastusmatkat-label-${index}-kahvilakulut_ja_ravintolakulut`}
                    component="label"
                  >
                    {`g) ${t('kalastusmatkat.control.label7')}`}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name={`kalastusmatkat.${index}.kahvilakulut_ja_ravintolakulut`}
                    render={({ field, fieldState: { error } }) => (
                      <EnterAwareTextField
                        {...field}
                        id={`kalastusmatkat-label-${index}-kahvilakulut_ja_ravintolakulut`}
                        type="number"
                        disabled={syncing}
                        error={!!error}
                        helperText={!!error && t('common.errors.invalid_value')}
                        InputProps={{ endAdornment: <>&nbsp;€</> }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6} className={classes.gridLabel}>
                  <Typography
                    htmlFor={`kalastusmatkat-label-${index}-ruokakulut_ja_paivittaistavarakulut`}
                    component="label"
                  >
                    {`h) ${t('kalastusmatkat.control.label8')}`}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name={`kalastusmatkat.${index}.ruokakulut_ja_paivittaistavarakulut`}
                    render={({ field, fieldState: { error } }) => (
                      <EnterAwareTextField
                        {...field}
                        id={`kalastusmatkat-label-${index}-ruokakulut_ja_paivittaistavarakulut`}
                        type="number"
                        disabled={syncing}
                        error={!!error}
                        helperText={!!error && t('common.errors.invalid_value')}
                        InputProps={{ endAdornment: <>&nbsp;€</> }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6} className={classes.gridLabel}>
                  <Typography
                    htmlFor={`kalastusmatkat-label-${index}-kalastuslupakulut`}
                    component="label"
                  >
                    {`i) ${t('kalastusmatkat.control.label9')}`}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name={`kalastusmatkat.${index}.kalastuslupakulut`}
                    render={({ field, fieldState: { error } }) => (
                      <EnterAwareTextField
                        {...field}
                        id={`kalastusmatkat-label-${index}-kalastuslupakulut`}
                        type="number"
                        disabled={syncing}
                        error={!!error}
                        helperText={!!error && t('common.errors.invalid_value')}
                        InputProps={{ endAdornment: <>&nbsp;€</> }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6} className={classes.gridLabel}>
                  <Typography
                    htmlFor={`kalastusmatkat-label-${index}-kalastuspalvelukulut`}
                    component="label"
                  >
                    {`j) ${t('kalastusmatkat.control.label10')}`}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name={`kalastusmatkat.${index}.kalastuspalvelukulut`}
                    render={({ field, fieldState: { error } }) => (
                      <EnterAwareTextField
                        {...field}
                        id={`kalastusmatkat-label-${index}-kalastuspalvelukulut`}
                        type="number"
                        disabled={syncing}
                        error={!!error}
                        helperText={!!error && t('common.errors.invalid_value')}
                        InputProps={{ endAdornment: <>&nbsp;€</> }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6} className={classes.gridLabel}>
                  <Typography
                    htmlFor={`kalastusmatkat-label-${index}-muut_ostot_menot`}
                    component="label"
                  >
                    {`k) ${t('kalastusmatkat.control.label11')}`}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name={`kalastusmatkat.${index}.muut_ostot_menot`}
                    render={({ field, fieldState: { error } }) => (
                      <EnterAwareTextField
                        {...field}
                        id={`kalastusmatkat-label-${index}-muut_ostot_menot`}
                        type="number"
                        disabled={syncing}
                        error={!!error}
                        helperText={!!error && t('common.errors.invalid_value')}
                        InputProps={{ endAdornment: <>&nbsp;€</> }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>

            {index !== (initialValues.alueet || []).length - 1 && (
              <Divider light />
            )}
          </React.Fragment>
        ))}
      </Box>
    </FormLayout>
  );
}
