import { logAppVersion } from '@lukefi-private/ahti-util-version';
import { ThemeProvider } from '@material-ui/core';
import axios, { AxiosRequestConfig } from 'axios';
import React, { Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import AppShell from './AppShell';
import LoginShell from './LoginShell';
import { Kiitos, Ohjeet } from './pages';
import { persistor, store } from './store/store';
import theme from './theme';

axios.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = store.getState().auth.token;
  config.headers && (config.headers.Authorization = token);
  return config;
});

/**
 * Fixes an issue with virtual keyboard jumping over the content on mobile.
 */
function resizeHandler() {
  if (document.activeElement && document.activeElement.tagName == 'INPUT') {
    if ((document.activeElement as HTMLElement).scrollIntoView) {
      (document.activeElement as HTMLElement).scrollIntoView();
    }
  }
}

export default function App() {
  useEffect(() => {
    void logAppVersion();
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={null}>
            <Router>
              <Switch>
                <Route path="/lomake">
                  <AppShell />
                </Route>
                <Route path="/kiitos" component={Kiitos} />
                <Route path="/ohjeet" component={Ohjeet} />
                <Route path="/">
                  <LoginShell />
                </Route>
              </Switch>
            </Router>
          </Suspense>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}
