import React from 'react';
import { useTranslation } from 'react-i18next';

interface DateTimeProps {
  date: Date;
}

function LocalDateTime({ date }: DateTimeProps) {
  const { t } = useTranslation();

  return (
    <time dateTime={date.toISOString()}>
      {t('common.datetime', {
        date,
        formatParams: {
          date: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          },
        },
      })}
    </time>
  );
}

export default LocalDateTime;
