import {
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Area, FormLayout, Species } from '../elements';
import { useFormPage } from '../hooks';
import { getTrapName } from '../options/traps';
import useStyles from '../styles';
import { FormValues } from '../types/FormValues';

const schema = yup.object().shape({
  saaliit: yup.array().of(
    yup.object().shape({
      alue: yup.string().required(),
      pyydykset: yup.array().of(
        yup.object().shape({
          id: yup.number().required(),
          lajit: yup.array().of(yup.number()),
        })
      ),
    })
  ),
});

type SaaliitPyydyksittainValues = Pick<
  FormValues,
  'saaliit' | 'pyydykset_alueittain' | 'muu_pyydys'
>;

export default function SaaliitPyydyksittain() {
  const { t, i18n } = useTranslation();
  const classes = useStyles({});
  const { control, handleSubmit, register, initialValues, syncing, meta } =
    useFormPage<SaaliitPyydyksittainValues>({
      fields: ['saaliit', 'pyydykset_alueittain', 'muu_pyydys'],
      schema,
    });

  return (
    <FormLayout
      heading={t('saaliit_pyydyksittain.title')}
      syncing={syncing}
      onSubmit={handleSubmit}
    >
      <Box p={3}>
        {(initialValues.pyydykset_alueittain || [])
          .filter(
            (value) =>
              (value.pyydykset || []).filter(
                (v) => v.henkilot || v.kalastuspaivat
              ).length
          )
          .map((value, index) => (
            <React.Fragment key={value.alue}>
              <Box mb={4} mt={2}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h3">
                      <Area id={value.alue} hideNumber />
                    </Typography>

                    <input
                      type="hidden"
                      defaultValue={value.alue}
                      {...register(`saaliit.${index}.alue`)}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Typography variant="h4">{t('common.catch')}</Typography>
                  </Grid>

                  <Grid item xs={8}>
                    <Typography variant="h4">{t('common.caught')}</Typography>
                  </Grid>

                  {(value.pyydykset || [])
                    .filter((row) => row.henkilot && row.kalastuspaivat)
                    .map((row, j) => (
                      <React.Fragment key={row.id}>
                        <Grid item xs={4} className={classes.gridLabel}>
                          <Typography>
                            {getTrapName(row.id, i18n.language, initialValues)}

                            <input
                              type="hidden"
                              defaultValue={row.id}
                              {...register(
                                `saaliit.${index}.pyydykset.${j}.id`
                              )}
                            />
                          </Typography>
                        </Grid>

                        <Grid item xs={8}>
                          <FormControl fullWidth>
                            <InputLabel
                              id={`species-multiple-select-label-${value.alue}-${row.id}`}
                            >
                              {t('saaliit_pyydyksittain.control.label1')}
                            </InputLabel>

                            <Controller
                              control={control}
                              name={`saaliit.${index}.pyydykset.${j}.lajit`}
                              defaultValue={[]}
                              render={({ field, fieldState: { error } }) => (
                                <Select
                                  {...field}
                                  disabled={syncing}
                                  error={!!error}
                                  multiple
                                  MenuProps={{
                                    variant: 'menu',
                                    getContentAnchorEl: null,
                                  }}
                                  labelId={`species-multiple-select-label-${value.alue}-${row.id}`}
                                  id={`species-multiple-select-${value.alue}-${row.id}`}
                                  fullWidth
                                  renderValue={(selected) => (
                                    <div className={classes.chips}>
                                      {(selected as string[]).map((species) => (
                                        <Chip
                                          key={species}
                                          label={<Species id={species} />}
                                        />
                                      ))}
                                    </div>
                                  )}
                                >
                                  {(meta.species || [])
                                    .sort((a, b) => +a.jarj_nro - +b.jarj_nro)
                                    .map((s) => (
                                      <MenuItem key={s.id} value={+s.id}>
                                        <Checkbox
                                          checked={field.value.includes(+s.id)}
                                        />
                                        <Species id={s.id} />
                                      </MenuItem>
                                    ))}
                                </Select>
                              )}
                            />
                          </FormControl>
                        </Grid>
                      </React.Fragment>
                    ))}
                </Grid>
              </Box>

              {index !==
                (initialValues.pyydykset_alueittain || []).length - 1 && (
                <Divider light />
              )}
            </React.Fragment>
          ))}
      </Box>
    </FormLayout>
  );
}
