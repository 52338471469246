/**
 * Log application version and hash of the latest commit to the console
 *
 */
export const logAppVersion = async () => {
  const { version, hash } = await import('./version.json');
  console.log(
    `%cApplication version ${version} - ${hash}`,
    'background-color: #006efd; color: #fff; font-weight: bold;' +
      'display: inline-block; padding: 4px 8px; text-align: center; border-radius: 4px;'
  );
};
