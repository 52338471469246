import { Box, Grid, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { EnterAwareTextField, FormLayout } from '../elements';
import { useFormPage } from '../hooks';
import ageGroups, { AgeGroup } from '../options/age_groups';
import traps from '../options/traps';
import useStyles from '../styles';
import { FormValues, TrapAgeGroup } from '../types/FormValues';
import { optionalNumberField } from '../util/numberField';

const schema = yup.object().shape({
  asuntokunta_pyydykset_ikaryhmittain: yup.array().of(
    yup.object().shape({
      pyydys: yup.number().required(),
      ikaryhmat: yup.array().of(
        yup.object().shape({
          id: yup.number().required(),
          henkilot: optionalNumberField,
        })
      ),
    })
  ),
  muu_pyydys: yup.string().when(
    'asuntokunta_pyydykset_ikaryhmittain',
    // When "Muu pyydys" has usage, the related text field is made required.
    (value: TrapAgeGroup[], schema: yup.StringSchema) => {
      const otherRow = value.find((row) => row.pyydys == 9);

      if (otherRow && otherRow.ikaryhmat) {
        const hasValues = otherRow.ikaryhmat.filter(
          (row) => (row.henkilot || 0) > 0
        );

        return hasValues.length
          ? schema.required('pyydykset_alueittain.control.error1')
          : schema;
      }

      return schema;
    }
  ),
});

type AsuntokuntaPyydyksetValues = Pick<
  FormValues,
  | 'asuntokunta_kalastuspaivat'
  | 'asuntokunta_pyydykset_ikaryhmittain'
  | 'muu_pyydys'
  | 'asuntokunta_ikaryhmat'
>;

export default function AsuntokuntaPyydykset() {
  const { t, i18n } = useTranslation();
  const classes = useStyles({});
  const { control, register, handleSubmit, initialValues, syncing } =
    useFormPage<AsuntokuntaPyydyksetValues>({
      fields: [
        'asuntokunta_pyydykset_ikaryhmittain',
        'asuntokunta_ikaryhmat',
        'muu_pyydys',
      ],
      schema,
    });

  /**
   * Only select trap usage for age groups selected on page 2 (AsuntokuntaKalastaneet)
   */
  const onlySelectedGroups = (group: AgeGroup) => {
    if (initialValues) {
      const selectedGroups = (initialValues.asuntokunta_ikaryhmat || [])
        .filter((g) => g.henkilot.filter((h) => h.lkm).length)
        .map((g) => g.id);

      return selectedGroups.indexOf(group.id) > -1;
    }

    return true;
  };

  return (
    <FormLayout
      heading={t('pyydykset_ikaryhmittain.title')}
      syncing={syncing}
      onSubmit={handleSubmit}
    >
      <Box p={3}>
        <Grid container spacing={1}>
          <Grid item xs={3}></Grid>
          <Grid item xs={9}>
            <Grid container spacing={1}>
              {ageGroups.filter(onlySelectedGroups).map((group) => (
                <Grid key={group.id} item xs={2} className={classes.gridHeader}>
                  <Typography>{group.name[i18n.language || 'fi']}</Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>

          {traps.map((trap, index) => {
            return (
              <React.Fragment key={index}>
                <Grid item xs={3} className={classes.gridLabel}>
                  <Typography>{trap.name[i18n.language || 'fi']}</Typography>
                  <input
                    type="hidden"
                    defaultValue={trap.id}
                    {...register(
                      `asuntokunta_pyydykset_ikaryhmittain.${index}.pyydys`
                    )}
                  />
                </Grid>
                <Grid item xs={9}>
                  <Grid container spacing={1}>
                    {ageGroups.filter(onlySelectedGroups).map((group, j) => (
                      <Grid key={group.id} item xs={2}>
                        <input
                          type="hidden"
                          defaultValue={group.id}
                          {...register(
                            `asuntokunta_pyydykset_ikaryhmittain.${index}.ikaryhmat.${j}.id`
                          )}
                        />
                        <Controller
                          control={control}
                          name={`asuntokunta_pyydykset_ikaryhmittain.${index}.ikaryhmat.${j}.henkilot`}
                          render={({ field, fieldState: { error } }) => (
                            <EnterAwareTextField
                              {...field}
                              type="number"
                              disabled={syncing}
                              error={!!error}
                              helperText={
                                !!error && t('common.errors.invalid_value')
                              }
                              inputProps={{
                                'aria-label': `Pyydystä ${trap.name.fi} käyttäneet henkilöt, ${group.name.fi}`,
                              }}
                            />
                          )}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
      </Box>

      <Box m={3}>
        <Controller
          control={control}
          name="muu_pyydys"
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              fullWidth
              disabled={syncing}
              label={t('pyydykset_alueittain.control.label3')}
              helperText={!!error && t('common.errors.invalid_value')}
              inputProps={{
                'aria-label': t('pyydykset_alueittain.control.label3')!,
              }}
              InputLabelProps={{ shrink: true }}
              error={!!error}
            />
          )}
        />
      </Box>
    </FormLayout>
  );
}
