import { useRouteMatch } from 'react-router';
import { FormPath } from '../pages/formPaths';

/**
 * Get form sub path part form url's pathname
 * @returns
 */
export default function useFormPath() {
  const routeMatch = useRouteMatch<{ formPage: FormPath }>(`/lomake/:formPage`);

  if (!routeMatch?.params.formPage) {
    throw new Error(
      'useFormPath hook should only be used on form sub pages (/lomake/sub-page)!'
    );
  }

  return routeMatch.params.formPage;
}
