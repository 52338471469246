import React from 'react';
import { useTranslation } from 'react-i18next';

export default function SpeciesUnit({ id }: any) {
  const { t } = useTranslation();

  if (!id) return <>-</>;

  return (
    <>
      {id == '20' || id == '21' ? t(`common.units.kpl`) : t(`common.units.kg`)}
    </>
  );
}
