import { Box, Grid, InputAdornment } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { EnterAwareTextField, FormLayout, Species, SpeciesUnit } from '../elements';
import { useFormPage } from '../hooks';
import useStyles from '../styles';
import { FormValues } from '../types/FormValues';
import { sumCatchAmountsPerSpecies } from '../util/formData';
import { optionalNumberField } from '../util/numberField';

const schema = yup.object().shape({
  saaliit_ravinnoksi: yup.array().of(
    yup.object().shape({
      id: yup.number().required(),
      ravinnoksi_prosenttia: optionalNumberField.max(100, 'saaliit_ravinnoksi.error.max_percentage'),
    })
  ),
});

type SaaliitRavinnoksiValues = Pick<FormValues, 'saaliit_ravinnoksi' | 'saalismaarat'>;

export default function SaaliitRavinnoksi() {
  const { t } = useTranslation();
  const classes = useStyles({});
  const { control, handleSubmit, register, initialValues, syncing } = useFormPage<SaaliitRavinnoksiValues>({
    fields: ['saaliit_ravinnoksi', 'saalismaarat'],
    schema,
  });

  return (
    <FormLayout heading={t('saaliit_ravinnoksi.title')} syncing={syncing} onSubmit={handleSubmit}>
      <Box p={3}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Typography variant="h4">{t('common.species')}</Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="h4">{t('common.caught')}</Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="h4">{t('saaliit_ravinnoksi.control.label1')}</Typography>
          </Grid>

          {sumCatchAmountsPerSpecies(initialValues.saalismaarat).map((laji: any, index: number) => (
            <React.Fragment key={index}>
              <Grid item xs={4} className={classes.gridLabel}>
                <Typography>
                  <Species id={laji.id} />
                </Typography>

                <input type="hidden" defaultValue={laji.id} {...register(`saaliit_ravinnoksi.${index}.id`)} />
              </Grid>

              <Grid item xs={4} className={classes.gridLabel}>
                <Typography data-testid="catch-amount">
                  {laji.maara || 0} <SpeciesUnit id={laji.id} />
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Controller
                  control={control}
                  name={`saaliit_ravinnoksi.${index}.ravinnoksi_prosenttia`}
                  render={({ field, fieldState: { error } }) => (
                    <EnterAwareTextField
                      {...field}
                      type="number"
                      disabled={syncing}
                      error={!!error}
                      helperText={!!error && t(error.message!)}
                      inputProps={{
                        'aria-label': `Saaliista ${laji.id} ravinnoksi käytetty osuus, prosentteina`,
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                    />
                  )}
                />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    </FormLayout>
  );
}
