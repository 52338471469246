import * as yup from 'yup';
import { TrapAgeGroup } from '../types/FormValues';
import { filterUsedTraps } from '../util/formData';
import { optionalNumberField } from '../util/numberField';

export const schema = yup.object().shape({
  pyydykset_alueittain: yup.array().of(
    yup.object().shape({
      alue: yup.string().required(),
      pyydykset: yup.array().of(
        yup.object().shape(
          {
            id: yup.number().required(),
            henkilot: yup.number().when('kalastuspaivat', {
              is: (value?: number) => value != undefined,
              then: yup
                .number()
                .typeError('pyydykset_alueittain.error.henkilot_required')
                .required('pyydykset_alueittain.error.henkilot_required')
                .min(0, 'common.errors.invalid_value'),
              otherwise: optionalNumberField,
            }),
            kalastuspaivat: yup.number().when('henkilot', {
              is: (value?: number) => value != undefined,
              then: yup
                .number()
                .typeError('pyydykset_alueittain.error.kalastuspaivat_required')
                .required('pyydykset_alueittain.error.kalastuspaivat_required')
                .min(0, 'common.errors.invalid_value'),
              otherwise: optionalNumberField,
            }),
          },
          [['henkilot', 'kalastuspaivat']]
        )
      ),
    })
  ),
});

/**
 * Form a list of trap ids that are marked as used, meaning that asuntokunta_pyydykset_ikaryhmittain
 * list has at least one age group with persons count > 0 for that particular trap.
 * @param trapsAgeGroups
 * @returns
 */
export const findIdsOfUsedTraps = (
  trapsAgeGroups: TrapAgeGroup[] = []
): number[] => filterUsedTraps(trapsAgeGroups).map(({ pyydys }) => pyydys);
