import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import RootState from '../store/types';

type SpeciesProps = {
  id: string | number;
};

export default function Species({ id }: SpeciesProps) {
  const species = useSelector(
    (state: RootState) =>
      state.meta.species &&
      state.meta.species.find((species) => +species.id === +id)
  );
  const { i18n } = useTranslation();

  if (!species) return <>-</>;

  return (
    <>
      {i18n.language == 'fi' &&
        species.fi.replace(/^./, (str: string) => str.toUpperCase())}
      {i18n.language == 'en' &&
        (species.en || '-').replace(/^./, (str: string) => str.toUpperCase())}
      {i18n.language == 'sv' &&
        species.sv.replace(/^./, (str: string) => str.toUpperCase())}
    </>
  );
}
