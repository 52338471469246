import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0033a0',
      contrastText: '#fff',
    },
    secondary: { main: '#ff8200', contrastText: '#fff' },
    action: {
      disabledBackground: '#0033a0',
      disabled: '#fff',
    },
  },
  typography: {
    fontFamily: 'Titillium Web',
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 'none',
        textTransform: 'none',
      },
    },
    MuiTypography: {
      h1: {
        fontSize: '2.215rem',
        fontWeight: 400,
      },
      h3: {
        fontWeight: 500,
        fontSize: '1.75rem',
        marginBottom: '1.5rem',
      },
      h4: {
        fontWeight: 600,
        fontSize: '1rem',
      },
      subtitle2: {
        display: 'block',
      },
    },
  },
  props: {
    MuiTypography: {
      variantMapping: {
        subtitle2: 'strong',
      },
    },
  },
});

export default theme;
