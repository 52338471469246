import jwtDecode from 'jwt-decode';
import { AuthTokenPayload } from '../../types/AuthToken';
import {
  AuthActions,
  AuthState,
  DELETE_TOKEN,
  SET_TOKEN,
  UserRole,
} from './types';

const initialState: AuthState = {
  token: undefined,
  role: UserRole.RESPONDENT,
};

export default function authReducer(
  state = initialState,
  action: AuthActions
): AuthState {
  switch (action.type) {
    case SET_TOKEN: {
      const token = jwtDecode<AuthTokenPayload>(action.payload);
      return {
        token: action.payload,
        role: token.u?.upn ? UserRole.ADMIN : UserRole.RESPONDENT,
      };
    }

    case DELETE_TOKEN:
      return {
        token: undefined,
        // revert back to default
        role: UserRole.RESPONDENT,
      };

    default:
      return state;
  }
}
