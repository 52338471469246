import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  h1: {
    fontSize: '1.5rem',
    fontWeight: 400,
  },
}));

export default function FormHeader({ children }: React.PropsWithChildren<any>) {
  const classes = useStyles();

  return (
    <Box p={3}>
      <Typography className={classes.h1} variant="h1" component="h2">
        {children}
      </Typography>
    </Box>
  );
}
