import { Box } from '@material-ui/core';
import React from 'react';
import useStyles from '../styles';

export default function MobileFooter({ children }: React.PropsWithChildren) {
  const classes = useStyles();
  return (
    <Box
      className={`${classes.mobileOnly} ${classes.screenOnly}`}
      position="sticky"
      bottom={0}
      display="flex"
      flexWrap="nowrap"
      justifyContent="flex-end"
    >
      {children}
    </Box>
  );
}
