import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import * as R from 'ramda';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Area, AreasMap, FormLayout } from '../elements';
import { useFormPage, useUserRole } from '../hooks';
import useStyles from '../styles';
import { Area as IArea } from '../types/Area';
import { FormValues } from '../types/FormValues';
import { isAuthorizedForMetaData } from '../util/auth';

const schema = yup.object().shape({
  alueet: yup.array().of(yup.string()),
});

const setAreasValue =
  (
    setValue: (field: 'alueet', value: string[]) => void,
    selectedAreas: string[] = []
  ) =>
  (area: string) => {
    setValue('alueet', R.symmetricDifference(selectedAreas, [area]));
  };

type AlueetValues = Pick<FormValues, 'alueet'>;

export default function Alueet() {
  const { t } = useTranslation();
  const classes = useStyles({});
  const { role } = useUserRole();
  const { control, handleSubmit, meta, setValue, syncing, watch } =
    useFormPage<AlueetValues>({
      schema,
      fields: ['alueet'],
    });
  const selectedAreas = watch('alueet');
  const onAreaMapClick = setAreasValue(setValue, selectedAreas);

  return (
    <FormLayout
      heading={t('alueet.title')}
      formInstructions={['alueet.instructions1']}
      syncing={syncing}
      onSubmit={handleSubmit}
    >
      <Box p={3}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="areas-multiple-select-label" shrink={true}>
                {t('alueet.control.label')}
              </InputLabel>

              <Controller
                control={control}
                name="alueet"
                defaultValue={[]}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    id="areas-multiple-select"
                    labelId="areas-multiple-select-label"
                    disabled={syncing}
                    error={!!error}
                    multiple
                    style={{ minHeight: '45px' }}
                    MenuProps={{
                      variant: 'menu',
                      getContentAnchorEl: null,
                    }}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {(selected as string[]).map((selectedArea) => (
                          <Chip
                            key={selectedArea}
                            data-testid="area-selection"
                            label={<Area id={selectedArea} />}
                          />
                        ))}
                      </div>
                    )}
                  >
                    {meta.areas
                      .filter(isAuthorizedForMetaData(role))
                      .map((area: IArea) => (
                        <MenuItem key={area.id} value={area.id}>
                          <Checkbox checked={field.value.includes(area.id)} />
                          <Area id={area.id} />
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box p={2}>
              <AreasMap
                value={selectedAreas ?? []}
                onAreaClick={onAreaMapClick}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </FormLayout>
  );
}
