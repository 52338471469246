import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { DeepPartial, FieldValues, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { getNextPath } from '../pages/formPaths';
import useAnswerValues from './useAnswerValues';
import useFormPath from './useFormPath';
import useMeta from './useMeta';
import useUpdatesAnswerValues from './useUpdatesAnswerValues';

type RouteResolver<V> = (data: V) => string;
type Route<V> = string | RouteResolver<V>;

type FormPageOptions<V> = {
  fields?: string[];
  routes?: { next: Route<V>; previous?: Route<V> };
  schema?: yup.ObjectSchema<any>;
  defaultValues?: DeepPartial<V>;
};

const resolveRoute = <V>(data: V, route?: Route<V>) =>
  typeof route === 'function' ? route(data) : route;

export default function useFormPage<V extends FieldValues>({
  schema,
  routes,
  defaultValues,
  fields = [],
}: FormPageOptions<V>) {
  const currentFormPath = useFormPath();
  const methods = useForm<V>({
    shouldFocusError: true,
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema || yup.object()),
    defaultValues,
  });

  const meta = useMeta();
  const { values: initialValues, loading } = useAnswerValues<V>(fields);
  const { update, updating, errors } = useUpdatesAnswerValues(fields);
  const history = useHistory();

  const next = async (data: V) => {
    try {
      await update(data);
      const nextRoute = resolveRoute(
        data,
        routes?.next ?? getNextPath(currentFormPath)
      );
      nextRoute && history.push(nextRoute);
    } catch (e) {
      console.error('Failed to update answer values');
    }
  };

  useEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  return {
    ...methods,
    // return undefined instead of Promise<void> to avoid type errors when used as an event handler
    handleSubmit: methods.handleSubmit((v: V) => void next(v)),
    meta,
    initialValues,
    errors,
    syncing: loading || updating,
  };
}
