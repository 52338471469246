import { Box, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { EnterAwareTextField, FormLayout } from '../elements';
import { useFormPage } from '../hooks';
import useStyles from '../styles';
import { FormValues } from '../types/FormValues';
import { optionalNumberField } from '../util/numberField';

const schema = yup.object().shape({
  asuntokunta_kalastuspaivat: optionalNumberField,
});

type AsuntokuntaKalastuspaivatValues = Pick<
  FormValues,
  'asuntokunta_kalastuspaivat'
>;

export default function AsuntokuntaKalastuspaivat() {
  const { t } = useTranslation();
  const classes = useStyles({});
  const { control, handleSubmit, syncing } =
    useFormPage<AsuntokuntaKalastuspaivatValues>({
      schema,
      fields: ['asuntokunta_kalastuspaivat'],
    });

  return (
    <FormLayout
      heading={t('asuntokunta_kalastuspaivat.title')}
      syncing={syncing}
      onSubmit={handleSubmit}
    >
      <Box p={3}>
        <Grid container spacing={1}>
          <Grid item xs={6} className={classes.gridLabel}>
            <Typography>{t('asuntokunta_kalastuspaivat.control')}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Controller
              control={control}
              name="asuntokunta_kalastuspaivat"
              render={({ field, fieldState: { error } }) => (
                <EnterAwareTextField
                  {...field}
                  type="number"
                  disabled={syncing}
                  error={!!error}
                  helperText={!!error && t('common.errors.invalid_value')}
                  inputProps={{
                    'aria-label': `Kalastuspäiviä yhteensä`,
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </FormLayout>
  );
}
