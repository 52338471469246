import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import RootState from '../store/types';

type AreaProps = {
  id: string | number;
  hideNumber?: boolean;
};

export default function Area({ id, hideNumber }: AreaProps) {
  const area = useSelector(
    (state: RootState) =>
      state.meta.areas && state.meta.areas.find((area: any) => +area.id === +id)
  );
  const { t, i18n } = useTranslation();

  if (!area) return <>-</>;

  return (
    <>
      {!hideNumber && `${area.id} `}
      {i18n.language == 'fi' &&
        area.fi.replace(/(^|[\s-])\S/g, (match: string) => match.toUpperCase())}
      {i18n.language == 'en' &&
        (area.en || '-').replace(/(^|[\s-])\S/g, (match: string) =>
          match.toUpperCase()
        )}
      {i18n.language == 'sv' &&
        area.sv.replace(/(^|[\s-])\S/g, (match: string) => match.toUpperCase())}
      {area.tyyppi == '1' && `, ${t('common.area_type_sea')}`}
    </>
  );
}
