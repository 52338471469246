import { Box, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React, { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import useStyles from '../styles';

interface DesktopActionButtonsProps {
  onSubmit: (e: FormEvent) => void;
  disabled: boolean;
  previousRoute?: string | false;
}

export default function DesktopActionButtons({
  previousRoute,
  onSubmit,
  disabled,
}: DesktopActionButtonsProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box pt={3}>
      <div className={classes.formFooterDesktop}>
        {previousRoute ? (
          <Button
            color="primary"
            variant="contained"
            disabled={disabled}
            onClick={() =>
              previousRoute ? history.push(previousRoute) : history.goBack()
            }
            startIcon={<ArrowBackIcon />}
          >
            {t('common.previous')}
          </Button>
        ) : (
          <div />
        )}

        <Button
          color="primary"
          variant="contained"
          disabled={disabled}
          onClick={onSubmit}
          endIcon={<ArrowForwardIcon />}
        >
          {t('common.next')}
        </Button>
      </div>
    </Box>
  );
}
