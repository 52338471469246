import axios from 'axios';
import { useState } from 'react';
import { ValidationError } from '../types/ValidationError';

export default function useUpdatesAnswerValues(fields?: string[]) {
  const [updating, setUpdating] = useState(false);
  const [errors, setErrors] = useState<ValidationError | null>(null);

  const update = async (values: any) => {
    setErrors(null);
    setUpdating(true);

    try {
      await axios.put(
        `${process.env.REACT_APP_DATA_API_URL}/data`,
        {
          answer_status_id: 'STATUS_STARTED',
          values,
        },
        {
          params: { fields },
        }
      );
    } catch (e: any) {
      if (e.response?.data) {
        setErrors(e.response?.data);
      }
      throw e;
    } finally {
      setUpdating(false);
    }
  };

  return { update, updating, errors };
}
