import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { FlagButton, FormContent, MobileFooter } from '../elements';
import { useAuth } from '../hooks';
import useStyles from '../styles';

interface LoginValues {
  formkey: string;
}

export default function Login() {
  const { t, i18n } = useTranslation();
  const { authenticate, authenticating, error: authError } = useAuth();

  const { setValue, handleSubmit, control } = useForm<LoginValues>({
    resolver: yupResolver(
      yup.object().shape({ formkey: yup.string().required() })
    ),
  });
  const classes = useStyles({});

  /**
   * If respondent id is in search path, set the login form value and attempt to login automatically
   */
  useEffect(() => {
    async function autoAuthenticate(respondentId: string) {
      await authenticate(respondentId);
    }

    const query = new URLSearchParams(window.location.search);
    const respondentId = query.get('id');

    if (respondentId) {
      void autoAuthenticate(respondentId);
      setValue('formkey', respondentId);
    }
  }, []);

  const onSubmit = (data: LoginValues) => {
    void authenticate(data.formkey);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormContent>
        <Box p={3}>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Typography variant="h1" component="h2">
                {t('common.title')}
              </Typography>
            </Grid>

            <Grid item md={4} className={classes.desktopFlags}>
              {i18n.language !== 'fi' && <FlagButton country="fi" lng="fi" />}
              {i18n.language !== 'sv' && <FlagButton country="se" lng="sv" />}
              {i18n.language !== 'en' && <FlagButton country="gb" lng="en" />}
            </Grid>
          </Grid>
        </Box>

        <Box p={3}>
          <Box>
            <Typography variant="body1">{t('welcome.content')}</Typography>
          </Box>
        </Box>

        <Box p={3}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="formkey"
                defaultValue={''}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    disabled={authenticating}
                    error={!!(error || authError)}
                    fullWidth
                    helperText={!!authError && t('common.login_failed')}
                    inputProps={{
                      'aria-label': t('common.loginkey'),
                    }}
                    label={t('common.loginkey')}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6} className={classes.desktopLoginButton}>
              <Button
                type="submit"
                color="primary"
                disabled={authenticating}
                variant="contained"
                endIcon={<ArrowForwardIcon />}
              >
                {t('common.start')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </FormContent>

      <MobileFooter>
        <Button
          type="submit"
          disabled={authenticating}
          color="primary"
          variant="contained"
          fullWidth
        >
          {t('common.start')}
        </Button>
      </MobileFooter>
    </form>
  );
}
