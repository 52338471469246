import {
  AppBar as MaterialAppBar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import React, { useState } from 'react';
import Flag from 'react-flagkit';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import logo from './assets/Luke_FI_valk_RGB.png';
import { DELETE_TOKEN } from './store/auth/types';
import RootState from './store/types';
import useStyles from './styles';

export default function AppBar() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
  const isAuthenticated = useSelector((state: RootState) => state.auth.token);

  const handleMenuClick = (event: React.MouseEvent) =>
    setMenuAnchor(event.currentTarget);

  const handleMenuClose = () => setMenuAnchor(null);

  const handleLogout = () => {
    handleMenuClose();
    dispatch({ type: DELETE_TOKEN });
  };

  const handleLanguageChange = (lang: string) => {
    void i18n.changeLanguage(lang);
    handleMenuClose();
  };

  return (
    <MaterialAppBar position="static" className={classes.screenOnly}>
      <Toolbar>
        <Box display="flex" flexGrow={1} justifyContent="center" ml={4}>
          <Link to="/">
            <img
              height={48}
              src={logo as string}
              alt={'Luonnonvarakeskuksen logo'}
            />
          </Link>

          <Typography variant="h1" className={classes.srOnly}>
            {t('title')}
          </Typography>
        </Box>

        <IconButton
          aria-label={t('common.open_user_menu')}
          edge="end"
          color="inherit"
          onClick={handleMenuClick}
        >
          <MoreIcon />
        </IconButton>

        <Menu
          id="kayttajatoiminnot-menu"
          anchorEl={menuAnchor}
          keepMounted
          open={Boolean(menuAnchor)}
          onClose={handleMenuClose}
        >
          {i18n.language !== 'fi' && (
            <MenuItem onClick={() => handleLanguageChange('fi')}>
              <Flag country="FI" size={25} />
              <Box ml={1}>
                <Typography>{t('lang.fi')}</Typography>
              </Box>
            </MenuItem>
          )}

          {i18n.language !== 'en' && (
            <MenuItem onClick={() => handleLanguageChange('en')}>
              <Flag country="GB" size={25} />
              <Box ml={1}>
                <Typography>{t('lang.en')}</Typography>
              </Box>
            </MenuItem>
          )}

          {i18n.language !== 'sv' && (
            <MenuItem onClick={() => handleLanguageChange('sv')}>
              <Flag country="SE" size={25} />
              <Box ml={1}>
                <Typography>{t('lang.sv')}</Typography>
              </Box>
            </MenuItem>
          )}

          <MenuItem onClick={() => history.push('/ohjeet')}>
            {t('ohjeet.title')}
          </MenuItem>

          {isAuthenticated && (
            <MenuItem onClick={handleLogout}>{t('common.logout')}</MenuItem>
          )}
        </Menu>
      </Toolbar>
    </MaterialAppBar>
  );
}
