import { Button } from '@material-ui/core';
import React, { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormPath } from '../hooks';
import { getPrevPath } from '../pages/formPaths';
import DesktopActionButtons from './DesktopActionButtons';
import FormContent from './FormContent';
import FormHeader from './FormHeader';
import FormInstructions from './FormInstructions';
import MobileFooter from './MobileFooter';
import PreviousButton from './PreviousButton';

type FormLayoutProps = React.PropsWithChildren & {
  heading: string;
  onSubmit: (e: FormEvent) => void;
  syncing: boolean;
  prevRoute?: string | false;
  formInstructions?: string[];
};

export default function FormLayout({
  children,
  heading,
  syncing,
  onSubmit,
  prevRoute,
  formInstructions,
}: FormLayoutProps) {
  const { t } = useTranslation();
  const currentFormPath = useFormPath();
  const prevRouteFromPropsOrDefault = prevRoute ?? getPrevPath(currentFormPath);

  return (
    <>
      <FormContent>
        <FormHeader>{`${currentFormPath}. ${heading}`}</FormHeader>

        {!!formInstructions && (
          <FormInstructions instructions={formInstructions} />
        )}

        {children}

        <DesktopActionButtons
          onSubmit={onSubmit}
          disabled={syncing}
          previousRoute={prevRouteFromPropsOrDefault}
        />
      </FormContent>

      <MobileFooter>
        {!!prevRouteFromPropsOrDefault && (
          <PreviousButton
            route={prevRouteFromPropsOrDefault}
            disabled={syncing}
          />
        )}

        <Button
          color="primary"
          variant="contained"
          fullWidth
          disabled={syncing}
          onClick={onSubmit}
        >
          {t('common.next')}
        </Button>
      </MobileFooter>
    </>
  );
}
