import axios from 'axios';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MetaState, SET_META } from '../store/meta/types';
import RootState from '../store/types';

export default function useMeta() {
  const dispatch = useDispatch();
  const meta = useSelector((state: RootState) => state.meta);

  useEffect(() => {
    const fetchMeta = async () => {
      try {
        const backendMeta = await axios.get<MetaState>(
          `${<string>process.env.REACT_APP_DATA_API_URL}/meta`,
          {
            withCredentials: true,
          }
        );

        dispatch({ type: SET_META, payload: backendMeta.data });
      } catch (e) {
        console.warn('Failed to fetch metadata');
      }
    };

    if (!meta || !meta.areas.length || !meta.species.length) {
      console.log('Meta is empty');
      void fetchMeta();
    }
  }, []);

  return meta;
}
