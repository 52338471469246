{
  "welcome": {
    "content": "Welcome to the Recreational Fisheries survey of Natural Resources Institute Finland! Please insert your respondent id and choose ”start” in the lower panel of the screen. Respondent id is the twelve-digit code in the top right corner of the printed form, which has been sent by mail."
  },
  "lang": {
    "fi": "Suomeksi",
    "en": "In English",
    "sv": "På svenska"
  },
  "common": {
    "start": "Start",
    "next": "Next",
    "previous": "Previous",
    "close": "Close",
    "send": "Send",
    "logout": "Logout",
    "women": "Women",
    "men": "Men",
    "count": "Count",
    "amount": "Amount",
    "catch": "Catch",
    "species": "Species",
    "caught": "Caught",
    "released": "Released",
    "loginkey": "Respondent ID",
    "login_failed": "Login failed. Please check your respondent ID.",
    "open_user_menu": "Open app menu",
    "area_type_sea": "sea area",
    "units": {
      "kg": "kg",
      "kpl": "units"
    },
    "example": "Example",
    "map": "Map of Finland",
    "title": "Recreational fishing in 2022",
    "lukeLogoAlt": "The logo of Natural Resources Institute Finland",
    "officialStatisticsAlt": "Finlands officiella statistik",
    "datetime": "{{date, datetime}}"
  },
  "asuntokunta": {
    "title": "Did any of the members of your household fish or catch crayfish in Finland in 2022?",
    "instructions1": "One household unit consists of all the persons living permanently in the same dwelling.",
    "instructions2": "Fishing is considered as such when a person has used gear of some kind (e.g., fishing net, fish trap, rod and line, or ice fishing rod) at least once in 2022. A person is considered to have fished even if they had only rowed or steered the boat while someone else was fishing.",
    "control": {
      "label": "Household's recreative fishing",
      "option1": "Yes, and they caught fish.",
      "option2": "Yes, but nobody caught any fish.",
      "option3": "None of the members of the household went fishing in 2022.",
      "error": "Please select an option."
    }
  },
  "asuntokunta_kalastaneet": {
    "title": "Mark the number of people belonging to your household who fished (or caught crayfish) in Finland in 2022.",
    "instructions1": "Fishing is considered as such when a person has used gear of some kind (e.g., fishing net, fish trap, rod and line, or ice fishing rod) at least once in 2022. A person is considered to have fished even if they had only rowed or steered the boat while someone else was fishing.",
    "control": {
      "error1": "The maximum allowed value is 20."
    }
  },
  "asuntokunta_osallistuneet": {
    "title": "How many of the members of your household did not fish at all or took part in fishing only by rowing or steering the boat in 2022?",
    "control": {
      "option1": "Did not fish at all.",
      "option2": "Took part in fishing only by rowing or steering the boat."
    }
  },
  "asuntokunta_harrastaneet": {
    "title": "How many of the members of your household who fished belonged to any of the following groups based on fishing or catching crayfish in 2022?",
    "instructions1": "Select only one group for each person who fished in 2022.",
    "control": {
      "option1": "Fishing was not an important hobby at all.",
      "option2": "Fishing was not that important a hobby.",
      "option3": "Fishing was quite an important hobby.",
      "option4": "Fishing was an extremely important hobby."
    }
  },
  "asuntokunta_kalastuspaivat": {
    "title": "In total, how many days did the members of your household fish in Finland in 2022?",
    "instructions1": "Fishing is considered as such when a person has used gear of some kind (e.g., fishing net, fish trap, rod and line, or ice fishing rod) at least once in 2022. A person is considered to have fished even if they had only rowed or steered the boat while someone else was fishing.",
    "instructions2": "Example: Person 1 fished using a rod and line for ten days. In addition, they took part in hauling nets for three days. Person 2 fished using fishing nets for three days. The total number of fishing days for the household amounts to 16 in 2022. ",
    "control": "Fishing days in total"
  },
  "pyydykset_ikaryhmittain": {
    "title": "Mark the number of people who belong to your household and used different types of gear in 2022 by age group."
  },
  "pyydykset_alueittain": {
    "title": "Mark the number of people who belong to your household and used different types of gear in 2022 by region, as well as the total number of fishing days.",
    "instructions1": "In the case of angling, ‘fishing day’ means that one person has used a certain type of rod or trolling gear during one day. In the case of gill nets, fish traps, crayfish traps, and trap nets, ‘fishing day’ means that a person has hauled the gear in question during one day.",
    "instructions2": "Example: Two persons hauled their nets in four days in Kainuu. The number of people is 2, and the number of fishing days is 8.",
    "control": {
      "label1": "Total number of people",
      "label2": "Number of fishing days",
      "label3": "Other gear - please specify!"
    },
    "error": {
      "henkilot_required": "Enter the total number of people.",
      "kalastuspaivat_required": "Enter the total number of fishing days."
    }
  },
  "alueet": {
    "title": "Select the areas where your household went fishing in the year 2022.",
    "instructions1": "You can select areas from the dropdown or by clicking them on the map.",
    "control": {
      "label": "Select areas"
    }
  },
  "kalastusmatkat": {
    "title": "Mark the number of day trips and longer fishing trips that the members of your household made in Finland in 2022, length of fishing trip, and associated travel costs.",
    "instructions1": "‘Day trip’ means a one day fishing trip in Finland. ‘Fishing trip’ means an overnight trip made in Finland where the <strong>primary purpose of the trip is fishing</strong> and where the point of departure is the household’s permanent place of residence.",
    "instructions2": "In items <strong>a</strong> and <strong>b</strong>, please mark the <strong>total number of  day trips and fishing trips</strong> made by all the members of the household.",
    "instructions3": "In item <strong>c</strong>, please mark the <strong>total number of fishing days</strong> for the overnight fishing trips made by all the members of the household.",
    "instructions4": "Do not include trips directed outside Finland",
    "instructions5": "In items <strong>d</strong> - <strong>k</strong>, please mark the travel costs associated with day trips and overnight fishing trips in euros separately for each region. <strong>Do not include fisheries management fee in fishing licence expenses.</strong>",
    "instructions6": "<i><strong>Example</strong>: Two persons made two separate day trips in Uusimaa sea area. The number of day trips in item a is 4. Additionally, one person made a fishing trip in Lapland with five fishing days. Number of overnight fishing trips in item b is 1, and number of fishing days in item c is 5.</i>",
    "link": "Please refer to the instructions page for examples.",
    "example1": "2 people in the household made two separate fishing trips of one day. The total number of fishing trips in field a should be 4.",
    "example2": "2 people in the household made fishing trips of 3 and 4 days together. The number of fishing trips in field b is 4 and the number of fishing days in field c is 14.",
    "example3": "One person made two fishing trips to Norway and was fishing while visiting a family member's cabin on the summer. The number of fishing trips in this case is zero.",
    "control": {
      "label1": "Number of daily visits",
      "label2": "Number of fishing trips",
      "label3": "Number of fishing days on fishing trips",
      "label4": "Fuel costs",
      "label5": "Travel ticket expenses (bus, train, flights)",
      "label6": "Accommodation expenses",
      "label7": "Café and/or restaurant expenses",
      "label8": "Food and/or groceries",
      "label9": "Fishing licence expenses",
      "label10": "Fishing services (guide, etc.)",
      "label11": "Other purchases and expenses"
    }
  },
  "saaliit_pyydyksittain": {
    "title": "Select the species that your household caught using different types of gear in 2022. Include both catch species which have been taken and catch species which have been released alive.",
    "control": {
      "label1": "Select species"
    }
  },
  "saaliit_maarat": {
    "title": "Mark in the table below an estimate of the catch that your household caught using different types of gear in Finland in 2022 (do not include fish that were released alive).",
    "instructions1": "Mark the ungutted weight of catch."
  },
  "saaliit_ravinnoksi": {
    "title": "Estimate by species how large a share (%) of the catch was used for human consumption.",
    "control": {
      "label1": "Used for human consumption"
    },
    "error": {
      "max_percentage": "The maximum allowed value is 100%"
    }
  },
  "saaliit_vapautetut": {
    "title": "Mark in the table below how much fish your household released alive.",
    "error": {
      "released_exceeds_caught": "The released amount cannot be greater than the caught amount."
    }
  },
  "lisatiedot": {
    "title": "Additional information about your household's recreative fishing",
    "control": {
      "label1": "Additional information"
    }
  },
  "yhteenveto": {
    "title": "Summary",
    "instructions1": "You can download your answers by pressing the <strong>Download PDF</strong> button.",
    "instructions2": "After you have checked your answers, click <strong>Submit</strong>. You can come back later to check and change your answers. If you change the answers, please resubmit the answers.",
    "kalastusmatkat_warning": "Total number of fishing trips per area exceed 100 trips.",
    "kalastusmatkat_kulut_warning": "You entered costs of more than €6,000. The cost of purchasing boats and fishing equipment, insurance and similar costs should not be included. Please check the information you have provided and correct your answers if necessary.",
    "saaliit_maarat_warning": "Entered catch exceeds 100 kilograms per species.",
    "check_answers": "Please check your answers and edit them if necessary.",
    "saveAsPDF": "Save as PDF"
  },
  "kiitos": {
    "title": "Thank you for participating!",
    "link": "",
    "stat_info": "For more information on recreational fishing, see the Natural Resources Institute Finland's recreational fishing statistics:",
    "stat_link": "https://www.luke.fi/en/statistics/recreational-fishing"
  },
  "ohjeet": {
    "title": "Instructions",
    "title1": "Logging in to the application",
    "title2": "Answering",
    "title3": "Questions & feedback",
    "instructions1": "Login to the application using your 12-digit respondent id.Respondent id is the twelve-digit code in the top right corner of the printed form, which has been sent by mail. If your mobile device is capable of reading QR codes, you can read the login link from the QR code in the printed form.",
    "instructions2": "Your answers are saved on each submit. You can logout at any time and return later to finish your answers. You can logout from the user menu on the top right corner of the screen.",
    "instructions3": "Move between answers using the navigation buttons on the bottom of the screen.",
    "instructions4": "unit consists of all the persons living permanently in the same dwelling.",
    "instructions4_bold": "A household ",
    "instructions5_bold": "Fishing",
    "instructions5": "is considered as such when a person has used gear of some kind (e.g., fishing net, fish trap, rod and line, or ice fishing rod) at least once in 2022.  A person is considered to have fished even if they had only rowed or steered the boat while someone else was fishing.",
    "instructions6": "The survey only includes fishing or crayfishing that took place in Finland. Fishing or catches that took place outside Finland should not be included in your answers.",
    "kalastusmatkat": "Household's fishing trips - example answers"
  }
}
