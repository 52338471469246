import { TextField, TextFieldProps } from '@material-ui/core';
import React from 'react';

const EnterAwareTextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  function EnterAwareTextFieldWithRef(props, ref) {
    return (
      <TextField
        {...props}
        inputRef={ref}
        /**
         * Block editing number fields with scroll
         */
        onWheel={() => (document.activeElement as HTMLElement).blur()}
        onKeyPress={(e) => {
          /**
           * Block inputting letters. Added because firefox doesnt handle this out of the box.
           */
          if (props.type === 'number') {
            const charCode =
              typeof e.which == 'undefined' ? e.keyCode : e.which;
            const charStr = String.fromCharCode(charCode);

            if (!charStr.match(/^[0-9,]+$/)) {
              e.preventDefault();
            }
          }
        }}
        onKeyUp={(e) => {
          e.preventDefault();

          if (e.keyCode === 13 && document.activeElement)
            (document.activeElement as HTMLElement).blur();
        }}
      />
    );
  }
);

export default EnterAwareTextField;
