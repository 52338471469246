export type Trap = {
  id: number;
  name: {
    [key: string]: string;
  };
};

export const traps: Trap[] = [
  {
    id: 1,
    name: {
      fi: 'Verkko',
      sv: 'Nät',
      en: 'Fishing net',
    },
  },
  {
    id: 2,
    name: {
      fi: 'Katiska tai rysä',
      sv: 'Katsa eller ryssja',
      en: 'Fish trap or trap net',
    },
  },
  {
    id: 3,
    name: {
      fi: 'Pilkkivapa',
      sv: 'Pilkspö',
      en: 'Ice fishing rod',
    },
  },
  {
    id: 4,
    name: {
      fi: 'Onki',
      sv: 'Metspö',
      en: 'Rod and line',
    },
  },
  {
    id: 5,
    name: {
      fi: 'Heittovapa',
      sv: 'Kastspö',
      en: 'Spinning rod',
    },
  },
  {
    id: 6,
    name: {
      fi: 'Perhovapa',
      sv: 'Flugspö',
      en: 'Fly rod',
    },
  },
  {
    id: 7,
    name: {
      fi: 'Vetouistin',
      sv: 'Dragrodd',
      en: 'Trolling',
    },
  },
  {
    id: 8,
    name: {
      fi: 'Rapumerta',
      sv: 'Kräftbur',
      en: 'Crayfish trap',
    },
  },
  {
    id: 9,
    name: {
      fi: 'Muu pyydys',
      sv: 'Annat redskap',
      en: 'Other gear',
    },
  },
];

export const findTrap = (id: number | string) =>
  traps.find((a) => +a.id === +id);

export const getTrapName = (
  id: number,
  language: string = 'fi',
  values: any = {}
) =>
  id == 9 && values.muu_pyydys
    ? values.muu_pyydys
    : findTrap(id)?.name[language] || '-';

export default traps;
