import { Typography } from '@material-ui/core';
import React from 'react';

type PreviewFieldProps = React.PropsWithChildren<{
  unit?: string | JSX.Element;
}>;

export default function PreviewField({ children, unit }: PreviewFieldProps) {
  const displayUnit = unit && !!React.Children.count(children);
  return (
    <Typography variant="body1">
      {children}
      {displayUnit && unit}
    </Typography>
  );
}
