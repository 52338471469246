import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import AppBar from './AppBar';
import { useTokenState, useUserRole } from './hooks';
import useTokenCookieParser from './hooks/useTokenCookieParser';
import { Login } from './pages';
import { FormPath, getFullFormPath } from './pages/formPaths';

export default function LoginShell() {
  // parse tikal token if present
  useTokenCookieParser();

  const isAuthenticated = useTokenState();
  const { isAdmin } = useUserRole();

  // If the user has a token, the user is redirected to the
  // first page of the survey whitch depends on the user's role
  if (isAuthenticated) {
    const startPage = isAdmin ? FormPath.VASTAUSKOODI : FormPath.ASUNTOKUNTA;
    return <Redirect to={getFullFormPath(startPage)} />;
  }

  return (
    <>
      <AppBar />
      <Switch>
        <Route path="/kirjaudu" component={Login} />
        <Redirect from="/" to="/kirjaudu" />
      </Switch>
    </>
  );
}
