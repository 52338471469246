import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import en from './assets/locales/en/translation.json';
import fi from './assets/locales/fi/translation.json';
import sv from './assets/locales/sv/translation.json';

void i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'fi',
    debug: process.env.NODE_ENV !== 'production',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      fi: { translation: fi },
      sv: { translation: sv },
      en: { translation: en },
    },
    react: {
      transSupportBasicHtmlNodes: true,
    },
    detection: {
      order: ['querystring', 'localStorage'],
      lookupQuerystring: 'lng',
    },
  });

i18n.on('languageChanged', (lng: string) => {
  document.documentElement.setAttribute('lang', lng);

  if (i18n.store.data[lng] && i18n.store.data[lng].translation) {
    document.title =
      (i18n.store.data[lng].translation as any).common.title ||
      'Vapaa-ajan kalastus 2022';
  }
});
