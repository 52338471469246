import axios from 'axios';
import { useState } from 'react';
import { useHistory } from 'react-router';

export default function useFinishAnswer() {
  const history = useHistory();
  const [finishing, setFinishing] = useState(false);
  const [error, setError] = useState(false);

  const finish = async () => {
    try {
      setError(false);

      await axios.put(`${process.env.REACT_APP_DATA_API_URL}/data/finish`);
      history.push('/kiitos');
    } catch (e) {
      setError(true);
    } finally {
      setFinishing(false);
    }
  };

  return { finish, finishing, error };
}
