import { Box, Divider, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from '../styles';

type SummarySectionProps = React.PropsWithChildren<{
  title: string;
  divider?: boolean;
  instructions?: string | false;
}>;

function SummarySection({
  children,
  title,
  instructions,
  divider = true,
  ...rest
}: SummarySectionProps) {
  const classes = useStyles();
  return (
    <>
      <Box m={3} {...rest}>
        <Typography variant="h6" component="h3">
          {title}
        </Typography>

        {instructions && (
          <Box
            p={3}
            mt={2}
            className={classes.instructions}
            data-testid="summary-section-instructions"
          >
            <Typography>{instructions}</Typography>
          </Box>
        )}

        <Box p={3} pb={0}>
          {children}
        </Box>
      </Box>

      {divider && <Divider light />}
    </>
  );
}

export default SummarySection;
