import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  main: {
    height: '100%',
    position: 'relative',
    overflow: 'auto',
    boxSizing: 'border-box',
    backgroundColor: '#fff',
    '@media (min-width: 968px)': {
      margin: '50px 100px',
      padding: '50px',
      flexGrow: 0,
      overflow: 'hidden',
    },
    '@media print': {
      overflow: 'visible',
    },
  },
}));

export default function FormContent({ children }: React.PropsWithChildren) {
  const classes = useStyles();
  return <main className={classes.main}>{children}</main>;
}
