import { Box, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import AppBar from '../AppBar';
import FormContent from '../elements/FormContent';
import { DELETE_TOKEN } from '../store/auth/types';

export default function Kiitos() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: DELETE_TOKEN });
  }, []);

  return (
    <>
      <AppBar />
      <FormContent>
        <Box m={3}>
          <Typography variant="h5" component="h2">
            {t('kiitos.title')}
          </Typography>
          <Box mt={3}></Box>
          <Box mt={3}>
            <Typography>
              {t('kiitos.stat_info')}{' '}
              <a href={t('kiitos.stat_link')}>{t('kiitos.stat_link')}</a>.
            </Typography>
          </Box>
        </Box>
      </FormContent>
    </>
  );
}
