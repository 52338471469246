import * as R from 'ramda';

import {
  CatchesTraps,
  FishingTrip,
  ParticipatedAgeGroup,
  SpeciesCatchAmount,
  TrapAgeGroup,
  TrapArea,
} from '../types/FormValues';

const CATCH_NOTIFICATION_LIMIT = 100;
const TRIP_AMOUNT_NOTIFICATION_LIMIT = 100;
const TRIP_COST_NOTIFICATION_LIMIT = 6000;

export const filterParticipatedAgeGroups = (
  ageGroups: ParticipatedAgeGroup[] = []
) =>
  ageGroups.filter((group) =>
    (group.henkilot || []).find((h) => +(h.lkm || 0))
  );

const hasPersons = ({ henkilot }: { henkilot?: number }) =>
  henkilot && henkilot > 0;

/**
 * Select only used traps (trap.ikaryhmat.henkilot is greter than 0)
 * @param trapsAgeGroups
 * @returns
 */
export const filterUsedTraps = (trapsAgeGroups: TrapAgeGroup[] = []) =>
  trapsAgeGroups.filter(({ ikaryhmat }) => ikaryhmat.find(hasPersons));

/**
 * Select only area.pyydykset that are used (area.pyydykset.henkilot greater than 0)
 * @param trapsByArea
 * @returns
 */
export const filterUsedTrapsByArea = (trapsByArea: TrapArea[] = []) =>
  trapsByArea.map((trap) => ({
    alue: trap.alue,
    pyydykset: trap.pyydykset.filter(hasPersons),
  }));

type WithPyydykset = { pyydykset: unknown[] };

/**
 * FlatMap pyydykset and pyydykset.lajit resulting in a flat array of trap catch entries
 */
const flatMapAllCatches = R.chain<WithPyydykset, SpeciesCatchAmount>(
  R.pipe(R.pathOr([], ['pyydykset']), R.chain(R.pathOr([], ['lajit'])))
);

const sumNumbersWithDefaults = (a = 0, b = 0) => a + b;

const sumSpeciesCatches = R.reduce<SpeciesCatchAmount, SpeciesCatchAmount>(
  (obj, { id, maara }) => ({
    id,
    maara: sumNumbersWithDefaults(obj.maara, maara),
  }),
  {} as SpeciesCatchAmount
);

const sortByAmountDesc = R.sortWith<SpeciesCatchAmount>([
  R.descend(R.pathOr(0, ['maara'])),
]);

/**
 * Sum catch amounts per species
 */
export const sumCatchAmountsPerSpecies = R.pipe(
  R.defaultTo([]),
  flatMapAllCatches,
  R.collectBy(R.prop('id')),
  R.chain<any, any, any>(sumSpeciesCatches),
  sortByAmountDesc
);

/**
 * Check if any of saalismaarat.pyydykset.lajit contains a property maara greater than 100
 * @param catches
 * @returns
 */
export const hasCatchOverNotificationLimit = (
  catches: CatchesTraps<SpeciesCatchAmount>[] = []
) =>
  R.any(
    R.propSatisfies(R.lt(CATCH_NOTIFICATION_LIMIT), 'maara'),
    flatMapAllCatches(catches)
  );

/**
 * Undefined safe number comparison
 * @param prop
 * @returns
 */
const isOverTripAmountLimit = (prop: number = 0) =>
  prop > TRIP_AMOUNT_NOTIFICATION_LIMIT;

const isOverCostLimit = (prop: number = 0) =>
  prop > TRIP_COST_NOTIFICATION_LIMIT;

const anyPropSatisfies = (props: string[], predicate: (a: any) => boolean) =>
  R.pipe(R.defaultTo({}), R.pick(props), R.values, R.any(predicate));

/**
 * Check if a value of a prop listed is over [TRIP_AMOUNT_NOTIFICATION_LIMIT]
 * @param kalastusmatkat
 * @returns
 */
const hasTripValueOverLimit = anyPropSatisfies(
  [
    'yhden_paivan_kalastusmatkojen_maara',
    'yon_yli_kalastusmatkojen_maara',
    'yon_yli_kalastusmatkat_yhteensa',
  ],
  isOverTripAmountLimit
);

/**
 * Check if a value of a prop listed is over [TRIP_AMOUNT_NOTIFICATION_LIMIT]
 * @param kalastusmatkat
 * @returns
 */
const hasTripCostOverLimit = anyPropSatisfies(
  ['majoituskulut', 'matkakulut', 'kalastuslupakulut'],
  isOverCostLimit
);

/**
 * Check if a fishing trip cost entry has value over [TRIP_AMOUNT_NOTIFICATION_LIMIT]
 * @param trips
 * @returns
 */
export const hasTripsOverNotificationLimit = (trips: FishingTrip[] = []) =>
  R.any(hasTripValueOverLimit, trips);

/**
 * Check if a fishing trip cost entry has value over [TRIP_COST_NOTIFICATION_LIMIT]
 * @param values Form values
 * @returns
 */
export const hasTripCostsOverNotificationLimit = (trips: FishingTrip[] = []) =>
  R.any(hasTripCostOverLimit, trips);
