import { createStore } from 'redux';
import { persistCombineReducers, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './rootReducer';

const persistConfig = {
  key: 'root',
  storage,
  debug: true,
  whitelist: ['auth', 'meta'],
};

const persistedReducer = persistCombineReducers(persistConfig, rootReducer);

const enhancer =
  process.env.NODE_ENV === 'development' &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION__?.();

let store = createStore(persistedReducer, enhancer);
let persistor = persistStore(store);

export { store, persistor };
