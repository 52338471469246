import { UserRole } from '../store/auth/types';

/**
 * Quick 'n dirty way to filter meta data entries based on user role.
 * The id 999 is code for unknown and is hidden from respondents and
 * visible for admin users.
 * @param role
 * @returns
 */
export const isAuthorizedForMetaData =
  (role: UserRole) =>
  (group: { id: number | string; [key: string]: unknown }) =>
    group.id != 999 || role === UserRole.ADMIN;
