import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {
  Area,
  FormContent,
  FormHeader,
  FormInstructions,
  MobileFooter,
  PreviewField,
  PreviousButton,
  Species,
  SpeciesUnit,
  SummarySection,
} from '../elements';
import PrintHeader from '../elements/PrintHeader';
import { useFinishAnswer, useFormPage, useUserRole } from '../hooks';
import ageGroups from '../options/age_groups';
import { getTrapName } from '../options/traps';
import useStyles from '../styles';
import {
  CatchesTraps,
  FishingTrip,
  FormValues,
  SpeciesCatchAmount,
} from '../types/FormValues';
import {
  filterParticipatedAgeGroups,
  filterUsedTraps,
  filterUsedTrapsByArea,
  hasCatchOverNotificationLimit,
  hasTripCostsOverNotificationLimit,
  hasTripsOverNotificationLimit,
} from '../util/formData';
import { FormPath } from './formPaths';

export default function Yhteenveto() {
  const history = useHistory();
  const { finish, finishing } = useFinishAnswer();
  const { isAdmin } = useUserRole();
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const { initialValues: values, syncing } = useFormPage<FormValues>({
    routes: { next: '/kiitos' },
  });

  const withGeneralInstruction = (instruction: string | string[]) =>
    [instruction]
      .flat()
      .concat([t('yhteenveto.check_answers')])
      .join('\n\n');

  const onlyParticipatedAgeGroups = filterParticipatedAgeGroups(
    values.asuntokunta_ikaryhmat
  ).map((ageGroup) => ({
    ...ageGroup,
    ...ageGroups.find(R.propEq('id', ageGroup.id))!,
  }));

  const onlyUsedTraps = filterUsedTraps(
    values.asuntokunta_pyydykset_ikaryhmittain
  );

  const onlyUsedTrapsByArea = filterUsedTrapsByArea(
    values.pyydykset_alueittain
  );

  const shouldNotifyAboutCatchSize = hasCatchOverNotificationLimit(
    values.saalismaarat
  );

  const shouldNotifyAboutTripLength = hasTripsOverNotificationLimit(
    values.kalastusmatkat
  );

  const shouldNotifyAboutTripCosts = hasTripCostsOverNotificationLimit(
    values.kalastusmatkat
  );

  return (
    <>
      <PrintHeader />

      <FormContent>
        <FormHeader>13. {t('yhteenveto.title')}</FormHeader>
        <FormInstructions instructions={['yhteenveto.instructions1']} />
        <FormInstructions instructions={['yhteenveto.instructions2']} />

        <Box m={3} className={`${classes.screenOnly} ${classes.mobileOnly}`}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => window.print()}
            fullWidth
          >
            {t('yhteenveto.saveAsPDF')}
          </Button>
        </Box>

        {!syncing && (
          <>
            {isAdmin && (
              <SummarySection
                title={`0. ${t('vastauskoodit.title')}`}
                data-testid="vastauskoodit"
              >
                <Grid container spacing={1}>
                  <Grid item xs={6} className={classes.gridLabel}>
                    <Typography>
                      {t('vastauskoodit.contactedByControl.label')}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <PreviewField>
                      {t(
                        `vastauskoodit.contactedByControl.option${values.yhteydenottotapa!}`
                      )}
                    </PreviewField>
                  </Grid>

                  <Grid item xs={6} className={classes.gridLabel}>
                    <Typography>
                      {t('vastauskoodit.vastauskoodiControl.label')}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <PreviewField>
                      {t(
                        `vastauskoodit.vastauskoodiControl.option${values.vastauskoodi}`
                      )}
                    </PreviewField>
                  </Grid>

                  <Grid item xs={6} className={classes.gridLabel}>
                    <Typography>
                      {t('vastauskoodit.vastauskoodi_lisatieto')}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <PreviewField>{values.vastauskoodi_lisatieto}</PreviewField>
                  </Grid>
                </Grid>
              </SummarySection>
            )}

            <SummarySection title={`1. ${t('asuntokunta.title')}`}>
              <Typography data-testid="asuntokunta">
                {t(`asuntokunta.control.option${values.asuntokunta_kalastus!}`)}
              </Typography>
            </SummarySection>

            <SummarySection title={`2. ${t('asuntokunta_kalastaneet.title')}`}>
              <Grid container spacing={1}>
                <Grid container spacing={1} item xs={12}>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <Typography>{t('common.women')}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>{t('common.men')}</Typography>
                  </Grid>
                </Grid>

                {onlyParticipatedAgeGroups.map((group) => (
                  <Grid
                    key={group.id}
                    container
                    item
                    spacing={1}
                    xs={12}
                    data-testid="asuntokunta-kalastaneet"
                  >
                    <Grid item xs={4} className={classes.gridLabel}>
                      <Typography>
                        {group.name[i18n.language || 'fi']}
                      </Typography>
                    </Grid>

                    {group.henkilot.map((row) => (
                      <Grid key={row.sukupuoli} item xs={4}>
                        <PreviewField>{row.lkm}</PreviewField>
                      </Grid>
                    ))}
                  </Grid>
                ))}
              </Grid>
            </SummarySection>

            <SummarySection
              title={`3. ${t('asuntokunta_osallistuneet.title')}`}
              data-testid="asuntokunta-osallistuneet"
            >
              <Grid container spacing={1}>
                <Grid item xs={6}></Grid>

                <Grid item xs={6} className={classes.gridLabel}>
                  <Typography>{t('common.count')}</Typography>
                </Grid>

                <Grid item xs={6} className={classes.gridLabel}>
                  <Typography>
                    {t('asuntokunta_osallistuneet.control.option1')}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <PreviewField>
                    {values.asuntokunta_osallistuneet?.ei_kalastanut}
                  </PreviewField>
                </Grid>

                <Grid item xs={6} className={classes.gridLabel}>
                  <Typography>
                    {t('asuntokunta_osallistuneet.control.option2')}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <PreviewField>
                    {
                      values.asuntokunta_osallistuneet
                        ?.osallistui_ainoastaan_soutamalla
                    }
                  </PreviewField>
                </Grid>
              </Grid>
            </SummarySection>

            <SummarySection
              title={`4. ${t('asuntokunta_harrastaneet.title')}`}
              data-testid="asuntokunta-harrastaneet"
            >
              <Grid container spacing={1}>
                <Grid item xs={6} className={classes.gridLabel}>
                  <Typography>
                    {t('asuntokunta_harrastaneet.control.option1')}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <PreviewField>
                    {values.kalastus_harrastuksena?.ei_lainkaan_tarkea}
                  </PreviewField>
                </Grid>

                <Grid item xs={6} className={classes.gridLabel}>
                  <Typography>
                    {t('asuntokunta_harrastaneet.control.option2')}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <PreviewField>
                    {values.kalastus_harrastuksena?.ei_kovin_tarkea}
                  </PreviewField>
                </Grid>

                <Grid item xs={6} className={classes.gridLabel}>
                  <Typography>
                    {t('asuntokunta_harrastaneet.control.option3')}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <PreviewField>
                    {values.kalastus_harrastuksena?.melko_tarkea}
                  </PreviewField>
                </Grid>

                <Grid item xs={6} className={classes.gridLabel}>
                  <Typography>
                    {t('asuntokunta_harrastaneet.control.option4')}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <PreviewField data-testid="summary-asuntokunta-harrastaneet">
                    {values.kalastus_harrastuksena?.erittain_tarkea}
                  </PreviewField>
                </Grid>
              </Grid>
            </SummarySection>

            <SummarySection
              title={`5. ${t('asuntokunta_kalastuspaivat.title')}`}
              data-testid="asuntokunta-kalastuspaivat"
            >
              <Grid container spacing={1}>
                <Grid item xs={6} className={classes.gridLabel}>
                  <Typography>
                    {t('asuntokunta_kalastuspaivat.control')}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <PreviewField>
                    {values.asuntokunta_kalastuspaivat}
                  </PreviewField>
                </Grid>
              </Grid>
            </SummarySection>

            <SummarySection
              title={`6. ${t('alueet.title')}`}
              data-testid="alueet"
            >
              {(values.alueet || []).map((value) => (
                <PreviewField key={value}>
                  <Area id={value} hideNumber />
                </PreviewField>
              ))}
            </SummarySection>

            <SummarySection
              title={`7. ${t('pyydykset_ikaryhmittain.title')}`}
              data-testid="asuntokunta-pyydykset"
            >
              <Grid container spacing={1}>
                <Grid item xs={3}></Grid>
                <Grid item xs={9}>
                  <Grid container spacing={1}>
                    {onlyParticipatedAgeGroups.map((group) => (
                      <Grid key={group.id} item xs={2}>
                        <Typography>
                          {group.name[i18n.language || 'fi']}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>

                {onlyUsedTraps.map((ageGroup) => (
                  <React.Fragment key={ageGroup.pyydys}>
                    <Grid item xs={3} className={classes.gridLabel}>
                      <Typography>
                        {getTrapName(ageGroup.pyydys, i18n.language, values)}
                      </Typography>
                    </Grid>

                    <Grid item xs={9}>
                      <Grid container spacing={1}>
                        {ageGroup.ikaryhmat.map((group) => (
                          <Grid key={group.id} item xs={2}>
                            <PreviewField>{group.henkilot}</PreviewField>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </SummarySection>

            <SummarySection
              title={`8. ${t('pyydykset_alueittain.title')}`}
              data-testid="pyydykset-alueet"
            >
              {onlyUsedTrapsByArea.map((value, index) => (
                <React.Fragment key={value.alue}>
                  <Box mb={4} mt={!index ? 0 : 2}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="h4">
                          <Area id={value.alue} hideNumber />
                        </Typography>
                      </Grid>

                      <Grid item xs={4}></Grid>
                      <Grid item xs={4}>
                        <Typography>
                          {t('pyydykset_alueittain.control.label1')}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>
                          {t('pyydykset_alueittain.control.label2')}
                        </Typography>
                      </Grid>

                      {value.pyydykset.map((trap) => (
                        <React.Fragment key={trap.id}>
                          <Grid item xs={4} className={classes.gridLabel}>
                            <Typography>
                              {getTrapName(trap.id, i18n.language, values)}
                            </Typography>
                          </Grid>

                          <Grid item xs={4}>
                            <PreviewField>{trap.henkilot}</PreviewField>
                          </Grid>

                          <Grid item xs={4}>
                            <PreviewField>{trap.kalastuspaivat}</PreviewField>
                          </Grid>
                        </React.Fragment>
                      ))}
                    </Grid>
                  </Box>

                  {index !== (values.alueet || []).length - 1 && (
                    <Divider light />
                  )}
                </React.Fragment>
              ))}
            </SummarySection>

            <SummarySection
              title={`9. ${t('kalastusmatkat.title')}`}
              instructions={
                (shouldNotifyAboutTripLength || shouldNotifyAboutTripCosts) &&
                withGeneralInstruction(
                  [
                    shouldNotifyAboutTripLength
                      ? t('yhteenveto.kalastusmatkat_warning')
                      : '',
                    shouldNotifyAboutTripCosts
                      ? t('yhteenveto.kalastusmatkat_kulut_warning')
                      : '',
                  ].filter(Boolean)
                )
              }
              data-testid="kalastusmatkat"
            >
              <Box m={3} mt={0}>
                {(values.kalastusmatkat || []).map(
                  (trip: FishingTrip, index: number) => (
                    <React.Fragment key={trip.alue}>
                      <Box mb={4} mt={2} data-testid="kalastusmatka">
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography variant="h6" component="h4">
                              <Area id={trip.alue} hideNumber />
                            </Typography>
                          </Grid>

                          <Grid item xs={6} className={classes.gridLabel}>
                            <Typography>
                              {t('kalastusmatkat.control.label1')}
                            </Typography>
                          </Grid>

                          <Grid item xs={6}>
                            <PreviewField>
                              {trip.paiva_kayntien_maara}
                            </PreviewField>
                          </Grid>

                          <Grid item xs={6} className={classes.gridLabel}>
                            <Typography>
                              {t('kalastusmatkat.control.label2')}
                            </Typography>
                          </Grid>

                          <Grid item xs={6}>
                            <PreviewField>
                              {trip.kalastusmatkojen_maara}
                            </PreviewField>
                          </Grid>

                          <Grid item xs={6} className={classes.gridLabel}>
                            <Typography>
                              {t('kalastusmatkat.control.label3')}
                            </Typography>
                          </Grid>

                          <Grid item xs={6}>
                            <PreviewField>
                              {trip.kalastuspaivien_maara_kalastusmatkoilla}
                            </PreviewField>
                          </Grid>

                          <Grid item xs={6} className={classes.gridLabel}>
                            <Typography>
                              {t('kalastusmatkat.control.label4')}
                            </Typography>
                          </Grid>

                          <Grid item xs={6}>
                            <PreviewField unit="€">
                              {trip.polttoainekulut}
                            </PreviewField>
                          </Grid>

                          <Grid item xs={6} className={classes.gridLabel}>
                            <Typography>
                              {t('kalastusmatkat.control.label5')}
                            </Typography>
                          </Grid>

                          <Grid item xs={6}>
                            <PreviewField unit="€">
                              {trip.matkalippukulut}
                            </PreviewField>
                          </Grid>

                          <Grid item xs={6} className={classes.gridLabel}>
                            <Typography>
                              {t('kalastusmatkat.control.label6')}
                            </Typography>
                          </Grid>

                          <Grid item xs={6}>
                            <PreviewField unit="€">
                              {trip.majoituskulut}
                            </PreviewField>
                          </Grid>

                          <Grid item xs={6} className={classes.gridLabel}>
                            <Typography>
                              {t('kalastusmatkat.control.label7')}
                            </Typography>
                          </Grid>

                          <Grid item xs={6}>
                            <PreviewField unit="€">
                              {trip.kahvilakulut_ja_ravintolakulut}
                            </PreviewField>
                          </Grid>

                          <Grid item xs={6} className={classes.gridLabel}>
                            <Typography>
                              {t('kalastusmatkat.control.label8')}
                            </Typography>
                          </Grid>

                          <Grid item xs={6}>
                            <PreviewField unit="€">
                              {trip.ruokakulut_ja_paivittaistavarakulut}
                            </PreviewField>
                          </Grid>

                          <Grid item xs={6} className={classes.gridLabel}>
                            <Typography>
                              {t('kalastusmatkat.control.label9')}
                            </Typography>
                          </Grid>

                          <Grid item xs={6}>
                            <PreviewField unit="€">
                              {trip.kalastuslupakulut}
                            </PreviewField>
                          </Grid>

                          <Grid item xs={6} className={classes.gridLabel}>
                            <Typography>
                              {t('kalastusmatkat.control.label10')}
                            </Typography>
                          </Grid>

                          <Grid item xs={6}>
                            <PreviewField unit="€">
                              {trip.kalastuspalvelukulut}
                            </PreviewField>
                          </Grid>

                          <Grid item xs={6} className={classes.gridLabel}>
                            <Typography>
                              {t('kalastusmatkat.control.label11')}
                            </Typography>
                          </Grid>

                          <Grid item xs={6}>
                            <PreviewField unit="€">
                              {trip.muut_ostot_menot}
                            </PreviewField>
                          </Grid>
                        </Grid>
                      </Box>

                      {index !== (values.alueet || []).length - 1 && (
                        <Divider light />
                      )}
                    </React.Fragment>
                  )
                )}
              </Box>
            </SummarySection>

            <SummarySection
              title={`10a. ${t('saaliit_pyydyksittain.title')}`}
              data-testid="saaliit-pyydyksittain"
            >
              {(values.saaliit || []).map((catchWithSpecies, index) => (
                <React.Fragment key={catchWithSpecies.alue}>
                  <Box mb={4} mt={2}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="h4">
                          <Area id={catchWithSpecies.alue} hideNumber />
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography>{t('common.catch')}</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography>{t('common.caught')}</Typography>
                      </Grid>

                      {catchWithSpecies.pyydykset.map((trap) => (
                        <React.Fragment key={trap.id}>
                          <Grid item xs={4} className={classes.gridLabel}>
                            <Typography>
                              {getTrapName(trap.id, i18n.language, values)}
                            </Typography>
                          </Grid>

                          <Grid item xs={8}>
                            {trap.lajit.map((value) => (
                              <Chip
                                key={value}
                                label={<Species id={value} />}
                              />
                            ))}
                          </Grid>
                        </React.Fragment>
                      ))}
                    </Grid>
                  </Box>

                  {index !== (values.pyydykset_alueittain || []).length - 1 && (
                    <Divider light />
                  )}
                </React.Fragment>
              ))}
            </SummarySection>

            <SummarySection
              title={`10b. ${t('saaliit_maarat.title')}`}
              instructions={
                shouldNotifyAboutCatchSize &&
                withGeneralInstruction(t('yhteenveto.saaliit_maarat_warning')!)
              }
              data-testid="saaliit-maarat"
            >
              {(values.saalismaarat || []).map(
                (value: CatchesTraps<SpeciesCatchAmount>, index: number) => (
                  <React.Fragment key={value.alue}>
                    <Box mb={4} mt={2}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="h6" component="h4">
                            <Area id={value.alue} hideNumber />
                          </Typography>
                        </Grid>

                        <Grid item xs={4}>
                          <Typography>{t('common.catch')}</Typography>
                        </Grid>

                        <Grid item xs={4}>
                          <Typography>{t('common.species')}</Typography>
                        </Grid>

                        <Grid item xs={4}>
                          <Typography>{t('common.amount')}</Typography>
                        </Grid>

                        {(value.pyydykset || [])
                          .filter(({ lajit }) => lajit?.length)
                          .map(({ id, lajit }) => (
                            <React.Fragment key={`${value.alue}-${id}`}>
                              {lajit.map((species, k) => (
                                <React.Fragment key={`${id}-${species.id}`}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={classes.gridLabel}
                                  >
                                    <Typography>
                                      {k === 0
                                        ? getTrapName(id, i18n.language, values)
                                        : ''}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={classes.gridLabel}
                                  >
                                    <Typography>
                                      <Species id={species.id} />
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <PreviewField
                                      unit={<SpeciesUnit id={species.id} />}
                                    >
                                      {species.maara}
                                    </PreviewField>
                                  </Grid>
                                </React.Fragment>
                              ))}
                            </React.Fragment>
                          ))}
                      </Grid>
                    </Box>
                    {index !== (values.alueet || []).length - 1 && (
                      <Divider light />
                    )}
                  </React.Fragment>
                )
              )}
            </SummarySection>

            <SummarySection
              title={`10c. ${t('saaliit_ravinnoksi.title')}`}
              data-testid="saaliit-ravinnoksi"
            >
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <Typography>{t('common.species')}</Typography>
                </Grid>

                <Grid item xs={8}>
                  <Typography>
                    {t('saaliit_ravinnoksi.control.label1')}
                  </Typography>
                </Grid>

                {(values.saaliit_ravinnoksi || []).map((laji) => (
                  <React.Fragment key={laji.id}>
                    <Grid item xs={4} className={classes.gridLabel}>
                      <Typography>
                        <Species id={laji.id} />
                      </Typography>
                    </Grid>

                    <Grid item xs={8}>
                      <Typography>
                        {laji.ravinnoksi_prosenttia || 0}%
                      </Typography>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </SummarySection>

            <SummarySection
              title={`11. ${t('saaliit_vapautetut.title')}`}
              data-testid="saaliit-vapautetut"
            >
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <Typography>{t('common.species')}</Typography>
                </Grid>

                <Grid item xs={8}>
                  <Typography>{t('common.released')}</Typography>
                </Grid>

                {(values.saaliit_vapautetut || []).map(({ vapautettu, id }) => (
                  <React.Fragment key={id}>
                    <Grid item xs={4} className={classes.gridLabel}>
                      <Typography>
                        <Species id={id} />
                      </Typography>
                    </Grid>

                    <Grid item xs={8}>
                      <PreviewField unit={<SpeciesUnit id={id} />}>
                        {vapautettu}
                      </PreviewField>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </SummarySection>

            <SummarySection
              title={`12. ${t('lisatiedot.title')}`}
              divider={false}
              data-testid="lisatiedot"
            >
              <PreviewField>{values.lisatiedot}</PreviewField>
            </SummarySection>
          </>
        )}

        <Box className={classes.formFooterDesktop} mt={8}>
          <Button
            color="primary"
            variant="contained"
            disabled={finishing}
            onClick={() => history.push(FormPath.LISATIEDOT)}
            startIcon={<ArrowBackIcon />}
          >
            {t('common.previous')}
          </Button>

          <Box display="flex">
            <Box mr={2}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => window.print()}
              >
                {t('yhteenveto.saveAsPDF')}
              </Button>
            </Box>

            <Button
              color="primary"
              variant="contained"
              disabled={finishing}
              onClick={() => void finish()}
            >
              {t('common.send')}
            </Button>
          </Box>
        </Box>
      </FormContent>

      <MobileFooter>
        <PreviousButton route={FormPath.LISATIEDOT} disabled={finishing} />

        <Button
          color="primary"
          variant="contained"
          disabled={finishing}
          fullWidth
          onClick={() => void finish()}
        >
          {t('common.send')}
        </Button>
      </MobileFooter>
    </>
  );
}
