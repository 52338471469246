import { Area } from '../../types/Area';
import { Species } from '../../types/Species';

export const SET_META = 'SET_META';

export interface SetMetaAction {
  type: typeof SET_META;
  payload?: {
    species: Species[];
    areas: Area[];
  };
}

export interface MetaState {
  species: Species[];
  areas: Area[];
}

export type MetaActions = SetMetaAction;
